import React from "react";
import { InnerHeader } from "../components/Header";
import Footer from "../components/Footer";
import { PeriodFilter } from "../components/PeriodFilter";
import { TdsReportCard } from "../components/CoinGiftCard";
import { useState } from "react";
import { useEffect } from "react";

function TdsReport() {
  const [dates, setDates] = useState(["",""]);

  return (
    <>
      <InnerHeader pageTitle="TDS Reports" backIcon="left-arrow-icon.svg" />

      <section className="tds-reports">
        <div className="container">
          <div className="filter-btn">
            <PeriodFilter setDates={setDates} />
          </div>
          <TdsReportCard dates={dates} />
        </div>
      </section>
      {/*  */}
      <Footer />
    </>
  );
}

export default TdsReport;
