import { Link } from "react-router-dom";
import pharmaRack from "../assets/images/pharmarack-retailer-img.png";
import coinsIcon from "../assets/images/icons/coins-icon.svg";
import amazonImg from "../assets/images/amazon-image.png";
import flipKart from "../assets/images/flipkart-image.png";
import React, { useState, useEffect } from "react";
import flagIcon from "../assets/images/icons/flag-icon.svg";
import tdsImage1 from "../assets/images/tds-reports-card-img-1.png";
import Modal from "react-bootstrap/Modal";
import filterByIcon from "../assets/images/icons/filter-by-icon.svg";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import moment from "moment";
import { saveAs } from "file-saver";
import { BASE_URL, WEB_URL, MOBILE_URL} from "../Constants";
import {getTokens } from "../helpers/helpers";
import 'moment-timezone';

const CoinGiftCard = (props) => {
  return (
    <>
      <div className="container">
        <Link to="/gift-description" className="coin-gift-card">
          <div className="img-wrapper">
            <img src={`${pharmaRack}`} alt="Product" />
          </div>
          <div className="content-wrapper">
            <h3>
              Pharmarack Cash <br />
              Giftcard - Rs. 375
            </h3>
            <h3>
              Value : <strong>Rs. 375</strong>
            </h3>
          </div>
          <div className="btn-wrapper">
            <Link to="#" className="btn-style transparent icon gray">
              <img src={`${coinsIcon}`} alt="Product" />
              75
            </Link>
          </div>
        </Link>

        <Link to="/gift-description" className="coin-gift-card">
          <div className="img-wrapper">
            <img src={`${amazonImg}`} alt="Product" />
          </div>
          <div className="content-wrapper">
            <h3>
              Amazon App
              <br />
              Giftcard - Rs. 75
            </h3>
            <h3>
              Value : <strong>Rs.75</strong>
            </h3>
          </div>
          <div className="btn-wrapper">
            <Link to="#" className="btn-style transparent icon gray">
              <img src={`${coinsIcon}`} alt="Product" />
              15
            </Link>
          </div>
        </Link>

        <Link to="/gift-description" className="coin-gift-card">
          <div className="img-wrapper">
            <img src={`${flipKart}`} alt="Product" />
          </div>
          <div className="content-wrapper">
            <h3>
              Flipkart App
              <br />
              Giftcard - Rs. 750
            </h3>
            <h3>
              Value : <strong>Rs.750</strong>
            </h3>
          </div>
          <div className="btn-wrapper">
            <Link to="#" className="btn-style transparent icon gray">
              <img src={`${coinsIcon}`} alt="Product" />
              150
            </Link>
          </div>
        </Link>
      </div>
    </>
  );
};

const MilestoneCard = (props) => {
  return (
    <>
      <div className="milestone-card-wrapper">
        <Link to="/mile-stone" className="milestone-card">
          <div className="img-wrapper">
            <span>
              <h3 className="white">₹150</h3>
            </span>
          </div>
          <div className="content-wrapper">
            <span className="p">Milestone 5</span>
            <h3>Get assured Rs.100 Cashback</h3>
            <p>
              Monthly Order Value
              <br />
              Above Rs 50000
            </p>
            <div className="action-wrapper">
              <Link to="#" className="btn-style light-blue icon rounded">
                <img src={`${flagIcon}`} alt="icon" />
                50000
              </Link>
              <h3>
                19907 more
                <br />
                malaamal needed.
              </h3>
            </div>
          </div>
        </Link>

        <Link to="/mile-stone" className="milestone-card">
          <div className="img-wrapper">
            <span>
              <h3 className="white">₹150</h3>
            </span>
          </div>
          <div className="content-wrapper">
            <span className="p">Milestone 4</span>
            <h3>Get assured Rs.100 Cashback</h3>
            <p>
              Monthly Order Value
              <br />
              Above Rs 50000
            </p>
            <div className="action-wrapper">
              <Link to="#" className="btn-style light-blue icon rounded">
                <img src={`${flagIcon}`} alt="icon" />
                50000
              </Link>
            </div>
          </div>
        </Link>

        <Link to="/mile-stone" className="milestone-card">
          <div className="img-wrapper">
            <span>
              <h3 className="white">₹150</h3>
            </span>
          </div>
          <div className="content-wrapper">
            <span className="p">Milestone 3</span>
            <h3>Get assured Rs.100 Cashback</h3>
            <p>
              Monthly Order Value
              <br />
              Above Rs 50000
            </p>
            <div className="action-wrapper">
              <Link to="#" className="btn-style light-blue icon rounded">
                <img src={`${flagIcon}`} alt="icon" />
                50000
              </Link>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

const TdsReportCard = ({ dates }) => {
  const [filterBy, setFilterBy] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  console.log(dates);
  useEffect(() => {
    get();

  }, [dates]);

  const get = async () => {
    setIsLoading(true);
    try {
      let ep = `${BASE_URL}/web-sdk/api/tdss?page=1&per_page=10&from_date=${dates[0]}&to_date=${dates[1]}&theme=#c12424`;
      console.log(ep);
      const searchParams = new URLSearchParams(ep.substring(ep.indexOf("?")));
      const response = await fetch(ep, {
        method: "GET",
        headers: {
          "X-Token": getTokens()?.token,
          "X-Access-Token":  getTokens()?.pid,
        },
      });
      const json = await response.json();
      setFilterBy(json.tdss);
      console.log(json, "ok");
    } catch (error) {
      console.log("error", error);
    }
  };

  const filterClose = () => setShowFilter(false);
  function filterOpen(e, img) {
    e.preventDefault();
    setShowFilter(true);
    console.log(img, "ook");
    setcertificateImg(img);
  }
  const download = (item) => {
    console.log(item, "ok ok");
    var element = document.createElement("a");
    var file = new Blob([`${item}`], { type: "image/*" });
    element.href = URL.createObjectURL(file);
    element.download = "image.png";
    element.click();
  };

  const [showFilter, setShowFilter] = useState(false);
  const [certificateImg, setcertificateImg] = useState("");

  return (
    <>
      <Modal
        show={showFilter}
        centered
        onHide={filterClose}
        className="filter-popup"
      >
        <Modal.Header>
          <Link to="#" className="btn-style black medium transparent text icon">
            Certificate
          </Link>
          <Link to="#" className="close-popup" onClick={filterClose}>
            Close
            <img src={`${popupCloseIcon}`} alt="Icon" />
          </Link>
        </Modal.Header>
        <Modal.Body>
          <div className="certificate-body-wrapper">
            <div className="img-wrapper">
              <img src={`${certificateImg}`} alt="Certificate" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {filterBy?.tdss?.length<1?<div>No Certificates Found</div>:
      filterBy &&
        filterBy?.tdss?.map((item, i) => (
          // filterBy.success === true ? (
          <div className="tds-reports-wrapper">
            <div className="tds-reports-card">
              <div className="row">
                <div className="col-9 align-self-center">
                  <div className="tds-reports-detail">
                    <div className="img-wrapper">
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={`${item.certificate_url}`}
                        alt="Certificate"
                      />
                    </div>
                    <div className="content-wrapper">
                      <h3>{item.retailer_name}</h3>
                      <p className="medium">
                        Month : {moment.utc(item.created_at).tz('Asia/Kolkata').format("MMMM")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3 align-self-center">
                  <div className="btn-wrapper">
                    <Link
                      to="#"
                      className="btn-style medium light-blue"
                      onClick={(e) => {
                        filterOpen(e, item.certificate_url);
                      }}
                    >
                      View
                    </Link>
                    {/*<Link to={item.certificate_url} className="btn-style medium">Download</Link>*/}
                    <a
                      href="#"
                      download
                      className="btn-style medium"
                      onClick={() => download(item.certificate_url)}
                    >
                      download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          // ):(<div>No Records Found</div>)
        ))
        }
    </>
  );
};

export { CoinGiftCard, MilestoneCard, TdsReportCard };
