import React from "react";


const TermsConditionRead = (props) => {

    return (
        <>
            <ul className="notification-card">
                <li className="active">
                    <div className="content-wrapper">
                        <p className="p">You have added new product.</p>
                        <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui iaculis.</p>
                        <span>Just now</span>
                    </div>
                </li>
                <li className="active">
                    <div className="content-wrapper">
                        <p className="p">You have added new product.</p>
                        <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui iaculis.</p>
                        <span>Just now</span>
                    </div>
                </li>
                <li>
                    <div className="content-wrapper">
                        <p className="p">You have added new product.</p>
                        <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui iaculis.</p>
                        <span>5 mins away</span>
                    </div>
                </li>
                <li>
                    <div className="content-wrapper">
                        <p className="p">You have added new product.</p>
                        <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui iaculis.</p>
                        <span>2 mins away</span>
                    </div>
                </li>
                <li>
                    <div className="content-wrapper">
                        <p className="p">You have added new product.</p>
                        <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui iaculis.</p>
                        <span>Just now</span>
                    </div>
                </li>
                <li>
                    <div className="content-wrapper">
                        <p className="p">You have added new product.</p>
                        <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui iaculis.</p>
                        <span>5 mins away</span>
                    </div>
                </li>
                <li>
                    <div className="content-wrapper">
                        <p className="p">You have added new product.</p>
                        <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui iaculis.</p>
                        <span>Just now</span>
                    </div>
                </li>
            </ul>
        </>
    )
}

const TermsConditionUnread = (props) => {

    return (
        <>
            <ul className="notification-card">
                <li className="active">
                    <div className="content-wrapper">
                        <p className="p">You have added new product.</p>
                        <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Dui iaculis.</p>
                        <span>5 mins away</span>
                    </div>
                </li>
                <li className="active">
                    <div className="content-wrapper">
                        <p className="p">You have added new product.</p>
                        <p className="medium">Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Dui iaculis.</p>
                        <span>Just now</span>
                    </div>
                </li>
            </ul>
        </>
    )
}

export {TermsConditionRead,TermsConditionUnread};
