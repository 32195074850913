import React from 'react';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import {InnerHeader} from "../components/Header";
import Footer from "../components/Footer";
import {TermsConditionRead,TermsConditionUnread} from "../components/NotificationCard";

function NotificationView() {

    return (
        <>
            <InnerHeader pageTitle="Notification" backIcon="left-arrow-icon.svg"/>

            <section className="notifications">
                <div className="container">
                    <div className="notifications-wrapper">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" className="btn-style">All</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second" className="btn-style">Unread</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <TermsConditionRead/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <TermsConditionUnread/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </section>

            <Footer/>

        </>
    );
}

export default NotificationView;
