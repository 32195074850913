import React, {useState} from 'react';

import {InnerHeader} from "../components/Header";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import bankIcon from "../assets/images/icons/bank-black-icon.svg";
import emailIcon from "../assets/images/icons/email-black-icon.svg";
import Modal from "react-bootstrap/Modal";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";
import HeadPhoneIcon from "../assets/images/icons/head-phone-icon.svg";

function AccountIssues() {

    const [showSuccess, setShowSuccess] = useState(false);
    const SuccessOpen = () => setShowSuccess(true);
    const SuccessClose = () => setShowSuccess(false);
    return (
        <>
            <InnerHeader pageTitle="Account Related Issues" backIcon="back-icon-black.svg" class="transparent-header"/>

            <section className="account-issues">
                <div className="container">
                    <Link to="/account-issues" className="account-anchor" onClick={SuccessOpen}>
                        <img src={`${emailIcon}`} alt="Icon"/>
                        <p>Change Email</p>
                    </Link>
                    <Link to="#" className="account-anchor" onClick={SuccessOpen}>
                        <img src={`${bankIcon}`} alt="Icon"/>
                        <p>Change Bank Account No or UPI</p>
                    </Link>
                </div>
            </section>

            <Footer/>

            <Modal show={showSuccess} centered onHide={SuccessClose} className="account-issues-popup">
                <Modal.Header>
                    <a href="#" className="close-popup" onClick={SuccessClose}>
                        Close
                        <img src={`${popupCloseIcon}`} alt="Icon"/>
                    </a>
                </Modal.Header>
                <Modal.Body>
                    <div className="account-body-wrapper text-center">
                        <img src={`${HeadPhoneIcon}`} alt="Success-upload"/>
                        <p>Please call on : <span>020-67660011</span> or write to
                            us at <span>care@pharmarack.com</span> for account issues and help</p>
                    </div>
                </Modal.Body>
            </Modal>


        </>
    );
}

export default AccountIssues;
