import {Link} from "react-router-dom";
import React, {useState} from 'react';


import {InnerHeader} from "../components/Header";
import Footer from "../components/Footer";
import pharmaRack from "../assets/images/pharmarack-retailer-img.png";
import Modal from "react-bootstrap/Modal";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";

function MyGiftCard() {
    const [showFilter, setShowFilter] = useState(false);
    const filterClose = () => setShowFilter(false);
    function filterOpen(){

        setShowFilter(true)
    }
    return (
        <>
            <InnerHeader pageTitle="My Gift Cards" backIcon="left-arrow-icon.svg" pageLabel=" "
                         class="gift-card-header"/>

            <section className="gift-cards-wrapper">

                <div className="container">
                    <Link to="/gift-description" className="coin-gift-card my-gift-card">
                        <div className="img-wrapper">
                            <img src={`${pharmaRack}`} alt="Product"/>
                        </div>
                        <div className="content-wrapper">
                            <h3>Pharmarack Cash <br/>
                                Giftcard - Rs. 375</h3>
                            <h3>Value : <strong>Rs. 375</strong></h3>
                        </div>
                        <div className="gift-btn-wrapper">
                            <Link to="#" className="btn-style medium light-blue" onClick={(e) => {filterOpen()}}>View</Link>
                            <Link to="#" className="btn-style medium">Share</Link>
                        </div>
                    </Link>

                </div>
            </section>

            <Footer/>
            <Modal show={showFilter} centered onHide={filterClose} className="filter-popup">
                <Modal.Header>
                    <Link to="#" className="btn-style black medium transparent text icon">Certificate
                    </Link>
                    <Link to="#" className="close-popup" onClick={filterClose}>
                        Close
                        <img src={`${popupCloseIcon}`} alt="Icon"/>
                    </Link>
                </Modal.Header>
                <Modal.Body>
                    <div className="certificate-body-wrapper">
                        <div className="img-wrapper">
                            <img src={`${pharmaRack}`} alt="Certificate"/>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
}

export default MyGiftCard;
