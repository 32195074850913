import { useEffect, useLayoutEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./assets/scss/main.scss";

import { Routes, Route } from "react-router-dom";

import HomeView from "./page/HomeView";
import TdsReports from "./page/TdsReports";
import SupportView from "./page/SupportView";
import OrderHistory from "./page/OrderHistory";
import OrderDescription from "./page/OrderDescription";
import TicketHistory from "./page/TicketHistory";
import TicketSingle from "./page/TicketSingle";
import WalletView from "./page/WalletView";
import WalletCash from "./page/WalletCash";
import CashRewards from "./page/CashRewards";
import CashRedeem from "./page/CashRedeem";
import LoyaltyDeals from "./page/LoyaltyDeals";
import RewardDetails from "./page/RewardDetails";
import MileStone from "./page/MileStone";
import CashbackDeal from "./page/CashbackDeal";
import SchemaView from "./page/SchemaView";
import BrandOffer from "./page/BrandOffer";
import BrandOfferDetail from "./page/BrandOfferDetail";
import ComboOffer from "./page/ComboOffer";
import ComboOfferDetail from "./page/ComboOfferDetail";
import NotificationView from "./page/NotificationView";
import ScratchCard from "./page/ScratchCard";
import MyGiftCard from "./page/MyGiftCard";
import GiftCards from "./page/GiftCards";
import GiftCardDescription from "./page/GiftCardDescription";
import ReferEarn from "./page/ReferEarn";
import FortuneWheel from "./page/FortuneWheel";
import HowToRedeem from "./page/HowToRedeem";
import AccountIssues from "./page/AccountIssues";
import ProductOffer from "./page/ProductOffer";
import {
  setCookie,
  getCookie,
  getUrlParameter,
  getTokens,
} from "./helpers/helpers";

function App() {


  useEffect(() => {
    return () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      window.addEventListener("resize", () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
      this.themeClass = getCookie("theme-class");
      let inputBtn = document.querySelector("#checkbox");
      inputBtn.checked = getCookie("checked");
      console.log(getCookie("checked"));
      inputBtn.checked = getCookie("checked") === null;
      if (getCookie("theme-class") === "") {
        inputBtn.checked = true;
      }
    };
  }, []);

  return (
    <div className="App">
      <div
        tokendata={new URLSearchParams(
          window.location.search
            .substring(1)
            .substring(window.location.search.substring(1).indexOf("?"))
        ).get("token")}
        piddata={new URLSearchParams(
          window.location.search
            .substring(1)
            .substring(window.location.search.substring(1).indexOf("?"))
        ).get("p_id")}
        id="flyy-database"
        style={{ height: 0, width: 0, display: "none" }}
      ></div>
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/tds-report" element={<TdsReports />} />
        <Route path="/support" element={<SupportView />} />
        <Route path="/order-history" element={<OrderHistory />} />
        <Route
          path="/order-description/:orderId"
          element={<OrderDescription />}
        />
        <Route path="/ticket-history" element={<TicketHistory />} />
        <Route path="/ticket-single/:disputeId" element={<TicketSingle />} />
        <Route path="/wallet" element={<WalletView />} />
        <Route path="/wallet-cash" element={<WalletCash />} />
        <Route path="/cash-rewards" element={<CashRewards />} />
        <Route path="/cash-redeem/:balance" element={<CashRedeem />} />
        <Route path="/loyalty-deals" element={<LoyaltyDeals />} />
        <Route path="/reward-details" element={<RewardDetails />} />
        <Route path="/mile-stone" element={<MileStone />} />
        <Route path="/cashback-deal" element={<CashbackDeal />} />
        <Route path="/schema/:schemeId" element={<SchemaView />} />
        <Route path="/brand-offer" element={<BrandOffer />} />
        <Route path="/brand-detail" element={<BrandOfferDetail />} />
        <Route path="/combo-offer" element={<ComboOffer />} />
        <Route path="/combo-detail/:schemeId" element={<ComboOfferDetail />} />
        <Route path="/notification" element={<NotificationView />} />
        <Route path="/my-gift-card" element={<MyGiftCard />} />
        <Route path="/gift-cards" element={<GiftCards />} />
        <Route path="/gift-description" element={<GiftCardDescription />} />
        <Route path="/card-scratch" element={<ScratchCard />} />
        <Route path="/refer-earn" element={<ReferEarn />} />
        <Route path="/wheel-of-fortune" element={<FortuneWheel />} />
        <Route path="/how-to-redeem/:steps" element={<HowToRedeem />} />
        <Route path="/account-issues" element={<AccountIssues />} />
        <Route path="/product-offer" element={<ProductOffer />} />
      </Routes>
    </div>
  );
}

export default App;
