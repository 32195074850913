import React, {useState} from 'react';


import {InnerHeader} from "../components/Header";
import Footer from "../components/Footer";
import Modal from "react-bootstrap/Modal";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";
import pharmaRack from "../assets/images/pharmarack-retailer-img.png";
import coinsIcon from "../assets/images/icons/coins-icon.svg";
import SuccessIcon from "../assets/images/icons/success-icon.svg";
import WhatsAppIcon from "../assets/images/icons/whatspp-icon.svg";
import EmailIcon from "../assets/images/icons/email-icon.svg";
import {Link} from "react-router-dom";

function GiftCardDescription() {
    const [showSuccess, setshowSuccess] = useState(false);
    const successClose = () => setshowSuccess(false);
    const successOpen = () => setshowSuccess(true);

    const [isActive, setActive] = useState(null);
    const handleToggle = () => {
        setActive(!isActive);
    }

    return (
        <>
            <InnerHeader pageTitle="Coins" backIcon="left-arrow-icon.svg" pageLabel=""
                         class="gift-card-header"/>

            <section className="gift-description-wrapper">
                <div className="container">
                    <div className="gift-description-banner text-center">
                        <h3>Pharmarack Cash<br/>
                            Giftcard - Rs. 375</h3>
                        <div className="img-wrapper">
                            <img src={`${pharmaRack}`} alt="Product"/>
                        </div>
                        <p>Value :<strong> Rs. 375</strong></p>
                    </div>
                    <div className="year-wrapper">
                        <div className="left-content">
                            <p>Validity : <strong>1 Year</strong></p>
                        </div>
                        <div className="right-content">
                            <p>Use : </p>
                            <Link to="#" className="btn-style transparent icon gray">
                                <img src={`${coinsIcon}`} alt="Product"/>
                                75
                            </Link>
                        </div>
                    </div>
                    <div className="about-wrapper">
                        <span>About</span>
                        <p>Pharmarack Gift Card</p>
                    </div>
                    <div className="redeem-wrapper">
                        <Link to="/how-to-redeem">How to Redeem your Gift Card? </Link>
                        <Link to="#">Terms & Conditions</Link>
                        <div className="btn-wrapper text-center">
                            <div className="btn-style" onClick={successOpen}>
                                REDEEM
                            </div>
                        </div>

                    </div>
                </div>

            </section>

            <Footer/>

            <Modal show={showSuccess} centered onHide={successClose} className="redeem-success-popup">
                <Modal.Header>
                    <a href="#" className="close-popup" onClick={successClose}>
                        Close
                        <img src={`${popupCloseIcon}`} alt="Icon"/>
                    </a>
                </Modal.Header>
                <Modal.Body>
                    <div className="success-body-wrapper">
                        <div className="img-wrapper">
                            <img src={`${SuccessIcon}`} alt="Icon"/>
                        </div>
                        <h2>Congratulations.!!!</h2>
                        <h3>Gift Card ID : XXXXXXX1234</h3>
                        <h3>Gift Card Pin : *************</h3>
                        <Link to="#" className="btn-style" onClick={handleToggle}>
                            SHARE
                        </Link>
                    </div>

                    <div className={`share-wrapper  ${isActive ? "active" : null}`}>
                        <h3>Share this on : </h3>
                        <ul>
                            <li>
                                <Link to="">
                                    <img src={`${WhatsAppIcon}`} alt="Icon"/>
                                </Link>
                            </li>
                            <li>
                                <Link to="">
                                    <img src={`${EmailIcon}`} alt="Icon"/>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
}

export default GiftCardDescription;
