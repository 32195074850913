
import React, { useState } from 'react';

import {Header} from "../components/Header";
import Footer from "../components/Footer";

import {BannerSlider} from "../components/BannerSlider";

function FortuneWheel() {

    return (
        <>
            <Header pageTitle="Wheel of Fortune"/>

            <BannerSlider/>

            <section className="fortune-wheel">
                <div className="container">
                    <div className="fortune-wheel-wrapper">
                        <div className="fortune-wheel-spinner">

                        </div>
                    </div>
                </div>
            </section>

            <Footer/>

        </>
    );
}

export default FortuneWheel;
