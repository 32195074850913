import React from "react";

import { InnerHeader } from "../components/Header";
import Footer from "../components/Footer";
import userIcon from "../assets/images/icons/user-icon.svg";
import redeemIcon from "../assets/images/icons/redeem-icon.svg";
import { Link } from "react-router-dom";
import { AnyOtherHelp } from "../components/TermsCondition";
import { RecentOrder01 } from "../components/RecentOrder";
import { useState } from "react";
import { useEffect } from "react";
import LZString from "lz-string";

function SupportView() {
  const [steps, setSteps] = useState("");
  const [dec, setDec] = useState("");
  useEffect(() => {
    const uncompressedString = steps;
    const compressedString =
      LZString.compressToEncodedURIComponent(uncompressedString);

    setDec(compressedString);
  }, []);
  useEffect(() => {
    const uncompressedString = steps;
    const compressedString =
      LZString.compressToEncodedURIComponent(uncompressedString);

    setDec(compressedString);
  }, [steps]);
  //   const zlib = require('zlib');

  // // The original string you want to compress
  // const originalString = 'This is a long string that we want to compress.';

  // // Compress the string using Gzip
  // const compressedData = zlib.gzipSync(originalString);
  return (
    <>
      <InnerHeader
        pageTitle="How can we help you?"
        backIcon="back-icon-black.svg"
        pageLabel="Ticket History"
        class="transparent-header"
        nextPage="/ticket-history"
      />
      
      <section className="recent-order">
        <div className="container">
          <RecentOrder01 setSteps={setSteps} />
          <div className="all-topics-wrapper">
            <h3>All topics</h3>
            <Link to="/account-issues" className="accordion-anchor">
              <img src={`${userIcon}`} alt="Icon" />
              <h3>Account Related Issues</h3>
            </Link>
            <Link to={`/how-to-redeem/${dec}`} className="accordion-anchor">
              <img src={`${redeemIcon}`} alt="Icon" />
              <h3>How To Redeem</h3>
            </Link>

            <AnyOtherHelp />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default SupportView;
