import {Link} from "react-router-dom";
import React from 'react';


import {InnerHeader} from "../components/Header";
import Footer from "../components/Footer";
import {MilestoneCard} from "../components/CoinGiftCard";

function RewardDetails() {


    return (
        <>
            <InnerHeader pageTitle="Reward Details" backIcon="left-arrow-icon.svg"/>

            <section className="reward-details-wrapper">
                <div className="container">
                    <div className="reward-banner-card text-center">
                        <h1 className="black">Complete the milestone task to win<br/>
                            assured rewards</h1>
                        <div className="row">
                            <div className="col-6">
                                <p><span className="me-2"><span className="green">1</span>/5</span>Milestone</p>
                            </div>
                            <div className="col-6">
                                <p>Ends on:<span className="ms-2">31st March</span></p>
                            </div>
                            <Link to="#">Achievement Details</Link>
                        </div>
                    </div>
                    <div className="milestone-main-wrapper">
                        <div className="milestone-steps-wrapper">
                            <span> </span>
                            <span className="active"> </span>
                            <span className="active"> </span>
                        </div>
                        <MilestoneCard/>
                    </div>
                </div>
            </section>

            <Footer/>


        </>
    );
}

export default RewardDetails;
