import { Link } from "react-router-dom";
import productImg1 from "../assets/images/icons/available-products-img-1.png";
import box1 from "../assets/images/box.png"
import React, { useState, useEffect } from "react";
import LoadingSpinner from "./loading";
import moment from "moment";
import { set } from "react-hook-form";
import {BASE_URL} from '../Constants'
import {getTokens } from "../helpers/helpers";
import 'moment-timezone';
const RecentOrder = (props) => {
    const filter = props.data;
   

  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const filterClose = () => setShowFilter(false);
  const filterOpen = () => setShowFilter(true);
 
  const [filterBy, setFilterBy] = useState("");
  // setIsLoading(true)

  useEffect(() => {
if(props.data){
  // console.log('calleded');
  setIsLoading(true)
  setFilterBy(props.data)
  setIsLoading(false)
}else{
  getRecentOrder();
}


},[filterBy.length || props.data]);

  const getRecentOrder = async () => {

    try {
    
      let ep=`${BASE_URL}/web-sdk/api/orders?page=1&per_page=10&from_date=&to_date=&theme=#c12424`
      const searchParams = new URLSearchParams(ep.substring(ep.indexOf('?')));
      const response = await fetch(
        ep,
        {
          headers: {
             "X-Token": getTokens()?.token,
            "X-Access-Token": getTokens()?.pid,
          },
        }
      );
      const json = await response.json();
      setFilterBy(json.orders);
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <div className="recent-order-wrapper">
        <h3>Recent Orders</h3>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="product-wrapper">
          {

          filterBy &&
            filterBy?.orders?.map((item, i) => (
              <>
                <Link
                  to={`/order-description/${item.order_id}`}
                  className="recent-order-card product-card"
                >
                  <div className="img-wrapper">
                    <img src={`${box1}`} alt="Certificate" />
                  </div>
                  <div className="content-wrapper">
                    <h3>Order ID : {item.order_id}</h3>
                    <p className="medium">{moment.utc(item.created_at).tz('Asia/Kolkata').format('llll')}</p>
                  </div>
                </Link>


              </>
            ))}
            </div>
)}
   </div>

    </>
  );
};

const RecentOrder01 = ({setSteps}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const filterClose = () => setShowFilter(false);
  const filterOpen = () => setShowFilter(true);

  const [filterBy, setFilterBy] = useState("");
  useEffect(() => {
    getRecentOrder();
  }, [filterBy.length]);

  const getRecentOrder = async () => {
    setIsLoading(true);

    const date = new Date();
    date.setDate(date.getDate() - 30);
    console.log(date);

    let yourDate = new Date();

    const a = date.toISOString().split("T")[0];
    const b = yourDate.toISOString().split("T")[0];
    try {
      let ep= `${BASE_URL}/web-sdk/api/orders?page=1&per_page=5&from_date=${a}&to_date=${b}&theme=#c12424`
      
      const searchParams = new URLSearchParams(ep.substring(ep.indexOf('?')));
      const response = await fetch(
       ep,
        {
          method: "GET",
          headers: {
             "X-Token": getTokens()?.token,
            "X-Access-Token": getTokens()?.pid,
            // "Access-Control-Allow-Headers": "*",
            // "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const json = await response.json();
      setFilterBy(json.orders);
      setSteps(json.redeem_steps)
    //   console.log(filterBy, "ok");
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className="recent-order-wrapper">
      <h3>Recent Orders</h3>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="product-wrapper">
          {

          filterBy &&
            filterBy.orders.slice(0, 2).map((item, i) => (
              <>
                <Link
                  to={`/order-description/${item.order_id}`}
                  className="recent-order-card product-card"
                >
                  <div className="img-wrapper">
                    <img src={`${box1}`} alt="Certificate" />
                  </div>
                  <div className="content-wrapper">
                    <h3>Order ID : {item.order_id}</h3>
                    <p className="medium">{moment.utc(item.created_at).tz('Asia/Kolkata').format('llll')}</p>
                  </div>
                </Link>


              </>
            ))}
              <Link to="/order-history" className="order-history-link">
                  Order History
                </Link>
        </div>
      )}
    </div>
  );
};

export { RecentOrder, RecentOrder01 };