import React, { useState,useEffect } from "react";

import { InnerHeader } from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import congratulationIcon from "../assets/images/icons/congratulation-icon.png";
import Modal from "react-bootstrap/Modal";
import popupCloseWhiteIcon from "../assets/images/icons/popup-close-white-icon.svg";
import congratulationImg from "../assets/images/loyalty-deals-main-image.png";
import scratchGiftImg from "../assets/images/scratch-gift-img-1.png";

import Confetti from "react-confetti";

import ScratchCard from "react-scratchcard";
function ScratchCardView() {
  const [showScratch, setShowScratch] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [cardData, setCardData] = useState(false);
  const startConfetti = (event) => {
    // 👇️ toggle isActive state on click
    setShowConfetti(true);
  };
  const scratchClose = () => {
    setShowScratch(false);
    setShowConfetti(false);
  };
  const scratchOpen = () => setShowScratch(true);

  // const { width, height } = useWindowSize()

  
  useEffect(() => {
    const fetchScratchCard = async () => {
      try {
        const response = await fetch("", {
            headers: {
                // "X-Token": getTokens()?.token,
                // "X-Access-Token": getTokens()?.pid,
              },
        })
     

        const data = await response.json();
        setCardData(data);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchScratchCard();
  }, []);

  return (
    <>
      <div className={`confetti-visibility ${showConfetti ? "active" : ""}`}>
        <Confetti width={200} height={500} />
      </div>
      <InnerHeader pageTitle="Scratch Cards" backIcon="left-arrow-icon.svg" />
      <section className="scratch-card-sec">
        <div className="container">
          <div className="scratch-card-wrapper">
            <div className="row">
              {cardData.map((card, index) => (
                <div className="col-6" key={index}>
                  <div className="scratch-card">
                    <h3 className="white">Congratulations.!!</h3>
                    <img src={congratulationIcon} alt="congratulation" />
                    <p className="p medium white">
                      You have won a Scratch Card.!!
                    </p>
                  </div>
                </div>
              ))}
            </div>  
          </div>
        </div>
      </section>
      {/* <section className="scratch-card-sec">
                <div className="container">
                    <div className="scratch-card-wrapper">
                       <div className="row">
                           <div className="col-6">
                               <div className="scratch-card" onClick={scratchOpen}>
                                   <h3 className="white">Congratulations.!!</h3>
                                   <img src={`${congratulationIcon}`} alt="congratulation"/>
                                   <p className="p medium white">You have won a Scratch <br/>
                                       Card.!!</p>
                               </div>
                           </div>
                           <div className="col-6">
                               <div className="scratch-card">
                                   <h3 className="white">Congratulations.!!</h3>
                                   <img src={`${congratulationIcon}`} alt="congratulation"/>
                                   <p className="p medium white">You have won a Scratch <br/>
                                       Card.!!</p>
                               </div>
                           </div>

                           <div className="col-6">
                               <div className="scratch-card">
                                   <h3 className="white">Congratulations.!!</h3>
                                   <img src={`${congratulationIcon}`} alt="congratulation"/>
                                   <p className="p medium white">You have won a Scratch <br/>
                                       Card.!!</p>
                               </div>
                           </div>
                           <div className="col-6">
                               <div className="scratch-card">
                                   <h3 className="white">Congratulations.!!</h3>
                                   <img src={`${congratulationIcon}`} alt="congratulation"/>
                                   <p className="p medium white">You have won a Scratch <br/>
                                       Card.!!</p>
                               </div>
                           </div>

                           <div className="col-6">
                               <div className="scratch-card">
                                   <h3 className="white">Congratulations.!!</h3>
                                   <img src={`${congratulationIcon}`} alt="congratulation"/>
                                   <p className="p medium white">You have won a Scratch <br/>
                                       Card.!!</p>
                               </div>
                           </div>
                           <div className="col-6">
                               <div className="scratch-card">
                                   <h3 className="white">Congratulations.!!</h3>
                                   <img src={`${congratulationIcon}`} alt="congratulation"/>
                                   <p className="p medium white">You have won a Scratch <br/>
                                       Card.!!</p>
                               </div>
                           </div>

                       </div>
                    </div>
                </div>
            </section> */}

      <Footer />

      <Modal
        show={showScratch}
        centered
        onHide={scratchClose}
        className="scratch-popup"
      >
        <Modal.Header>
          <Link to="#" className="close-popup" onClick={scratchClose}>
            <img src={`${popupCloseWhiteIcon}`} alt="Icon" />
          </Link>
        </Modal.Header>
        <Modal.Body>
          <p>
            Earned for NYB milestone
            <br />
            reached
          </p>
          <div className="scratch-body-wrapper">
            <div className="scratch-img-wrapper">
              <ScratchCard
                width={235}
                height={226}
                image={congratulationImg}
                finishPercent={80}
                onComplete={startConfetti}
              >
                <img src={`${scratchGiftImg}`} alt="Icon" />
              </ScratchCard>
            </div>
          </div>
          <p>Ref number : #qlS6Y</p>
          <p>Earned on 6th Jan 2023</p>
          <Link to="#" className="btn-style transparent white">
            Collect More
          </Link>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ScratchCardView;
