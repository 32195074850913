import React from "react";
import telephoneIcon from "../assets/images/icons/telephone-receiver-icon.svg";
import chatIcon from "../assets/images/icons/chat-icon.svg";

const Footer = ({}) =>{

    return(
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-3 align-self-center text-start">
                            <a href="tel:923156488259">
                                <img src={`${telephoneIcon}`} alt="telephoneIcon"/>
                            </a>
                        </div>
                        <div className="col-6 align-self-center text-center">
                            <p className="white">2023 © Pharmarack</p>
                        </div>
                        <div className="col-3 align-self-center text-end">
                            <a href="tel:923156488259">
                                <img src={`${chatIcon}`} alt="chatIcon"/>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
