import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InnerHeader } from "../components/Header";
import Footer from "../components/Footer";
import { BASE_URL } from '../Constants';
import { getTokens } from '../helpers/helpers';
import moment from 'moment';
import "moment-timezone"
function TicketSingle() {
    const disId = useParams()?.disputeId
    const [ticket, setTicket] = useState([])
    const getTicketHistory = async () => {
        try {
            const response = await fetch(`${BASE_URL}/web-sdk/api/disputes?page=1&per_page=10&from_date=&to_date=`, {
                method: 'GET',
                headers: {
                    "X-Token": getTokens()?.token,
                    "X-Access-Token": getTokens()?.pid,
                },
            })
            const res = await response.json()
            const progress = await res?.disputes?.disputes?.find((obj) => obj.dispute_id === disId)?.progress
            setTicket(progress)
        } catch (error) {
            console.log("error", error);
        }
    };
    useEffect(() => {
        getTicketHistory()
    }, [])
    return (
        <>
            <InnerHeader pageTitle={`Ticket No : ${disId}`} backIcon="back-icon-black.svg" class="transparent-header" />
            <section className="ticket-steps">
                <div className="container">
                    <ul className="steps">
                        {ticket?.map((item) => (
                            item && (<li className={item.reached === true ? 'active' : ''}>
                                <h3 className="light">{moment(item?.datetime).tz('Asia/Kolkata').format("LL")}</h3>
                                <p>{item.title.replace('{{datetime}}', moment(item?.datetime).tz('Asia/Kolkata').format("LL"))}</p>
                            </li>)
                        ))}
                    </ul>
                </div>
            </section>
            <Footer />
        </>
    );
}
export default TicketSingle;
