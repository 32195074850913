import balanceIcon from "../assets/images/icons/balance-wallet-icon.svg";
import { Link } from "react-router-dom";
import balanceCoinIcon from "../assets/images/icons/balance-coin-icon.svg";
import scratchIcon from "../assets/images/icons/scratch-icon.svg";
import React, { useEffect, useState } from "react";
import LoadingSpinner from "./loading";
import {BASE_URL} from "../Constants"
import {getTokens } from "../helpers/helpers";
import Modal from "react-bootstrap/Modal";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";
import successIcon from "../assets/images/icons/success-icon.svg";
import radioCircleIcon from "../assets/images/icons/radio-circle-icon.svg";
const WalletCardWrapper = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [wallet, setWallet] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [filterBy, setFilterBy] = useState("");
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const verifyopen = () => setShowModal(true);
  const verifyclose = () => setShowModal(false);
  useEffect(() => {
    getWallet();
  }, []);

  const getWallet = async () => {
    setIsLoading(true);

    try {
      let ep= `${BASE_URL}/web-sdk/api/users/wallet?theme=#c12424`
      const searchParams = new URLSearchParams(ep.substring(ep.indexOf('?')));
      const response = await fetch(ep
        ,
        {
          method: "GET",
          headers: {
             "X-Token": getTokens()?.token,
            "X-Access-Token": getTokens()?.pid,
          },
        }
      );
      const json = await response.json();
      setFilterBy(json.data);
      console.log(json.data.wallet, "ok");
      if(json.data.wallet[0].balance=="0.00"){
        verifyopen()
        setMessage("We are updating your wallet please check your wallet again after a few days, alternatively once the wallet is udpated we will send you a notification. Apologies for the inconvenience")
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="waller-card-wrapper">
          {filterBy &&
            filterBy.wallet.map((item, i) => (
              <div className="waller-card">
                <div className="row">
                  <div className="col-8 align-self-center">
                    <div className="wallet-detail">
                      <div className="img-wrapper">
                        <img src={`${balanceIcon}`} alt="Icon" />
                      </div>
                      <div className="content-wrapper">
                        <h3>Balance</h3>
                        <strong>₹ {(Math.round(item.balance * 100) / 100).toFixed(2)}</strong>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 align-self-center">
                    {(item.currency_code === "upi_bank_account") &&
                        <Link to={`/cash-redeem/${item?.balance}`} className="btn-style">
                          REDEEM
                        </Link>
                    }
                    {(item.currency_code !== "upi_bank_account") &&
                        <Link to="/gift-cards" className="btn-style">
                          REDEEM
                        </Link>
                    }

                  </div>
                </div>
               
                <div className="price-wrapper">
                  <div className="row">
                    <div className="col-6 align-self-center">
                      <h3 className="light">Total Earned</h3>
                      <h3 className="light">Total Redeemed</h3>
                    </div>
                    <div className="col-6 align-self-center text-end">
                      <h3>₹ {item.total_earned}</h3>
                      <h3>₹ {item.total_redeemed}</h3>
                    </div>
                  </div>
                </div>
                <Link to="/cash-rewards">All Transactions</Link>
              </div>
            ))}
        </div>
      )}
        <Modal
        show={showModal}
        centered
        onHide={verifyclose}
        className="success-popup"
      >
        <Modal.Header>
          <a href="#" className="close-popup" onClick={verifyclose}>
            Close
            <img src={`${popupCloseIcon}`} alt="Icon" />
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="success-body-wrapper text-center">
            <h3>{message}</h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { WalletCardWrapper };
