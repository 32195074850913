import Accordion from "react-bootstrap/Accordion";
import React, {useState} from "react";
import helpIcon from "../assets/images/icons/help-icon.svg";
import {BASE_URL} from "../Constants"
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg"
import Modal from "react-bootstrap/Modal";
import successIcon from "../assets/images/icons/success-icon.svg";
import {getTokens } from "../helpers/helpers";
const TermsCondition = (props) => {

    return (
        <>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <p>Terms & Conditions</p>
                    </Accordion.Header>
                    <Accordion.Body>
                        <ul className="conditions">
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua
                            </li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua
                            </li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua
                            </li>
                            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua
                            </li>

                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}




const AnyOtherHelp = ({}) => {

    const [message, setMessage] = useState("");
    const getMessgae = (e) => {
        // console.log(e.target.value)
        if(e.target.value===''){

        }
        setMessage(e.target.value)
    }
    async function orderMessage() {
        try {
            let ep=`${BASE_URL}/web-sdk/api/send_message?theme=#c12424`
            
            const searchParams = new URLSearchParams(ep.substring(ep.indexOf('?')));
            const response = await fetch(
                ep,
                {
                    method: "POST",

                    headers: {
                        "X-Token": getTokens()?.token,
                        "Content-Type": "application/json",
                        "X-Access-Token": getTokens()?.pid,
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify({
                        message: message
                    }),
                }
            );
            const json = await response.json();

            console.log(json, "ok");
            if (json.success === true){
                setShowSuccess(true)
                setMessage('')
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const [showSuccess, setShowSuccess] = useState(false);

   
    const SuccessOpen = () => setShowSuccess(true);
    const SuccessClose = () => setShowSuccess(false);
    return (
        <>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <img src={`${helpIcon}`} alt="Icon"/>
                        <h3>Any Other Help ?</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="form">
                            <div className="form-group">
                                <textarea className="form-control" value={message} onChange={getMessgae} placeholder="Write Comment...."></textarea>
                            </div>
                            <div className="form-group submit">
                                {/*<input type="submit" value="SUBMIT" className="btn-style" />*/}
                                <a href="#" onClick={orderMessage}  style={{pointerEvents : message===''?"none":"auto"}} className={message===''?"btn-style disable":"btn-style"}>SUBMIT</a>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Modal
        show={showSuccess}
        centered
        onHide={SuccessClose}
        className="success-popup"
      >
        <Modal.Header>
          <a href="#" className="close-popup" onClick={SuccessClose}>
            Close
            <img src={`${popupCloseIcon}`} alt="Icon" />
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="success-body-wrapper text-center">
            {/* <img src={`${successIcon}`} alt="Success-upload" /> */}
            <h3>Comment Submitted.!!</h3>
       
          </div>
        </Modal.Body>
      </Modal>
        </>
    )
}

export {TermsCondition,AnyOtherHelp};
