import React from "react";
import { BASE_URL, WEB_URL, MOBILE_URL} from "../Constants";
import { InnerHeader } from "../components/Header";
import Footer from "../components/Footer";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import LZString from "lz-string";
import {getTokens } from "../helpers/helpers";
function HowToRedeem() {
  const params = useParams();
  const [steps, setSteps] = useState();

  const step = LZString.decompressFromEncodedURIComponent(params?.steps);
  const steparr = step.split(".");

  const html = <div dangerouslySetInnerHTML={{ __html: steparr.join("") }} />;
  const redeemsteps = async (e) => {
    try {
      let ep= `${BASE_URL}/web-sdk/api/home?theme=#c12424`
      const searchParams = new URLSearchParams(ep.substring(ep.indexOf('?')));
      const response = await fetch(ep, {
        headers: {
          "X-Token": getTokens()?.token,
          "X-Access-Token": getTokens()?.pid,
        },
      });
      const json = await response.json();

      setSteps(json);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    redeemsteps();
  }, []);

  return (
    <>
      <InnerHeader
        pageTitle="How To Redeem"
        backIcon="back-icon-black.svg"
        class="transparent-header"
      />

      <section className="redeem-steps">
        <div className="container">
          {html}

          <div
            style={{ paddingTop: "20px" }}
            className="btn-wrapper text-center"
          >
            {/* <Link to="#" className="btn-style">
              CLOSE
            </Link> */}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default HowToRedeem;
