export function setCookie(name, value) {
    var db = document.getElementById("flyy-database");
    if (db) {
      db.setAttribute("data-" + name, value);
    }
  }
  
  export function getCookie(name) {
    var db = document.getElementById("flyy-database");
    if (db) {
      return db.getAttribute("data-" + name);
    } else {
      return null;
    }
  }

  export function deleteCookie(name) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }  

export function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    if (sPageURL != null) {
        var sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    } else {
        return null;
    }

}
export function getTokens() {
  var token = document.getElementById("flyy-database").getAttribute("tokendata");  
  var pid = document.getElementById("flyy-database").getAttribute("piddata");  
  return { token, pid }
}
