import React, { useEffect, useState } from "react";
import { InnerHeader } from "../components/Header";
import Footer from "../components/Footer";
import { TransactionCard } from "../components/TransactionCard";
import 'rc-pagination/assets/index.css'
import { Link } from "react-router-dom";
import balanceIcon from "../assets/images/icons/balance-wallet-icon.svg";
import { PeriodFilter } from "../components/PeriodFilter";
import { AmountFilter } from "../components/AmountFilter";
import { BASE_URL, WEB_URL, MOBILE_URL} from "../Constants";
import Modal from "react-bootstrap/Modal";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";
import Moment from "react-moment";
import filterByIcon from "../assets/images/icons/filter-by-icon.svg";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import LoadingSpinner from "../components/loading";
import { getTokens } from "../helpers/helpers";
// import Pagination from "rc-pagination";
// import "rc-pagination/assets/index.css";
// import ReactPaginate from 'react-paginate';
import InfiniteScroll from 'react-infinite-scroller';
import InfinitScroll from 'react-infinite-scroll-component'
import { render } from "@testing-library/react";
function CashReward() {
  const [isLoading, setIsLoading] = useState(false);
  const [walletData, setWalletData] = useState('');
  const [renderwallet, setRenderWallet] = useState('');
  const [page, setPage] = useState(1)
  const [totpage, setTPage] = useState(1)
  const [showtransaction, setShowtransaction] = useState(false);
  const [transactiondata, setTransactionData] = useState();
  const transactionClose = () => setShowtransaction(false);
  const transactionOpen = (trxdata) => {
    setTransactionData(trxdata);
    setShowtransaction(true);
  };

  const [dates, setDates] = useState(["", ""]);
  const filterClose = () => setShowFilter(false);
  const filterOpen = () => setShowFilter(true);
  const [showFilter, setShowFilter] = useState(false);
  useEffect(() => {
    getOrder();
  }, [walletData.length, dates, page]);
  function getValue(e) {
    getOrder();
    e.preventDefault();
    filterClose();
  }
  const getOrder = async () => {
    setIsLoading(true);
    try {
      let ep = `${BASE_URL}/web-sdk/api/users/wallet/upi_bank_account?page=${page}&per_page=5&from_date=${dates[0]}&to_date=${dates[1]}&theme=#c12424`
      const response = await fetch(
        ep,
        {
          method: "GET",
          headers: {
            "X-Token": getTokens()?.token,
            "X-Access-Token": getTokens()?.pid,
          },
        }
      );
      const json = await response.json();
      if (page == 1) {
        setTPage(json.transactions.total_pages)
        setWalletData(json)
        setRenderWallet(json)
      }
      else {
        json.transactions.userRewards = [...renderwallet?.transactions?.userRewards, ...json?.transactions?.userRewards]
        setRenderWallet(json)
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  const updatePage = () => {

    setPage(page + 1);
  };

  return (
    <>
      <InnerHeader pageTitle="Transactions" backIcon="left-arrow-icon.svg" />
      <section className="transactions-wrapper">
        <div className="transactions-inner-wrapper">
          <div className="container">
            <div className="cash-wrapper">
              <div className="row">
                <div className="col-4 align-self-center">
                  <div className="redeem-wrapper">
                    <div className="current-balance">
                      <div className="icon-wrapper">
                        <img src={`${balanceIcon}`} alt="Icon" />
                      </div>
                      <div className="balance">
                        <p>Balance</p>
                        <h2>₹{walletData && (Math.round(walletData?.wallet_data?.balance * 100) / 100).toFixed(2)}</h2>
                      </div>
                    </div>
                    <Link
                      to={`/cash-redeem/${walletData?.wallet_data?.balance}`}
                      className="btn-style"
                    >
                      Redeem
                    </Link>
                  </div>
                </div>
                <div className="col-8 align-self-end text-end">
                  <div className="total-wrapper">
                    <h3>
                      Total Earned :{" "}
                      {walletData && (Math.round(walletData?.wallet_data?.total_earned * 100) / 100).toFixed(2)}
                    </h3>
                    <h3>
                      Total Redeemed :{" "}
                      {walletData && (Math.round(walletData?.wallet_data?.total_redeemed * 100) / 100).toFixed(2)}{" "}
                    </h3>
                    {/*<AmountFilter/>*/}
                    {/* <Link
                        to="#"
                        style={{ height: "32px", fontSize: "12px", color: "black" }}
                        className="btn-style medium transparent gray blue-bg icon"
                        onClick={filterOpen}
                      >
                        Filter
                        <img src={`${filterByIcon}`} alt="Product" />
                      </Link> */}
                    <div style={{ marginTop: "10px" }}>
                      <PeriodFilter setDates={setDates}></PeriodFilter>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <div className="transactions-history-wrapper">
                {renderwallet &&
                  renderwallet?.transactions?.userRewards.map((item, i) => (
                    <div className="transaction-card" key={i}>
                      <div className="row">
                        <div className="col-6 align-self-center">
                          <div className="left-content">
                            {item.metadata.product_name.map((prod)=>(
                                <h3>{prod}</h3>
                            ))}
                            <h3>{!item.is_available && item.transaction_type === "credit" ? " (Pending)" : ""}</h3>
                          </div>
                        </div>
                        <div className="col-6 text-end align-self-center">
                          <div className="right-content">
                            <p style={{ color: item.transaction_type !== "debit" ? '#6BBE4D' : 'red' }}>₹ {item.value}</p>
                            {/*<p>Today,04:06 PM</p>*/}
                            <p>
                              <Moment
                                style={{ whiteSpace: "nowrap" }}
                                calendar
                                date={item.created_at}
                              />
                            </p>
                            {item.transaction_type === "debit" || item.metadata.scheme_name === "-" ? "" : (
                              <span
                                onClick={() => transactionOpen(item.metadata)}
                              >
                                View Details
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <div

              style={{ margin: "10px 0" }}
              onClick={updatePage
              }
              className={page < totpage ? "sbtn-style" : ''}
            >
            {page < totpage ? "Show more" : ''}
            </div>
          </div>
        </div>
      </section>
      {/* <Pagination
        style={{ textAlign: "center", marginTop: "10px" }}
        onChange={updatePage}
        current={page}
        total={walletData?.transactions?.total_count}
      /> */}
      <Footer />
      <Modal
        show={showtransaction}
        centered
        onHide={transactionClose}
        className="transaction-popup"
      >
        <Modal.Header>
          <Link to="#" className="close-popup" onClick={transactionClose}>
            Close
            <img src={`${popupCloseIcon}`} alt="Icon" />
          </Link>
        </Modal.Header>
        <Modal.Body>
          <div className="transactions-details-wrapper ">
            <h2>Details</h2>
            {/* <p className="blue">Cashback Amount : ₹ 4876</p> */}
            <p>Company : {transactiondata?.company_name}</p>
            {/* <p>Brand : {transactiondata.company_name}</p> */}
            <p>Scheme : {transactiondata?.scheme_name}</p>
            {/* <p>Invoice : ABC123</p> */}
            <p>Order ID : {transactiondata?.order_id}</p>
            <p>Product : {transactiondata?.product_name}</p>
            {/* <p>Bank Ref ID : ABC123</p> */}
            <div className="btn-wrapper text-center">
              <Link to="#" className="btn-style" onClick={transactionClose}>
                OK
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default CashReward;