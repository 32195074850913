import availableProductImg1 from "../assets/images/icons/available-products-img-1.png";
import balanceIcon from "../assets/images/icons/balance-wallet-icon.svg";
import balanceIconRed from "../assets/images/icons/balance-wallet-icon-red.svg";
import React,{useEffect,useState} from "react";
import LoadingSpinner from "./loading";
import moment from "moment";
import {Link} from "react-router-dom";
import filterByIcon from "../assets/images/icons/filter-by-icon.svg";
import Modal from "react-bootstrap/Modal";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import {getTokens } from "../helpers/helpers";
import {BASE_URL} from "../Constants"
import 'moment-timezone';

const JanMonthCardWrapper = (props) => {
    console.log(BASE_URL)
    const [data, setDate] = useState();
    const [filterBy, setFilterBy] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [startDate,setStartDate] = useState("");
    const [endDate,setEndDate] = useState("");
    const [period,setPeriod] = useState("");
    const filterClose = () => setShowFilter(false);
    const filterOpen = () => setShowFilter(true);
    const [showFilter, setShowFilter] = useState(false);

    useEffect(() => {
      getProductDeal();
    }, [period.length]);


    function getValue(e){
        getProductDeal();
        e.preventDefault();
        filterClose();
        console.log(startDate,endDate)
    }

    const getProductDeal = async () => {
        setIsLoading(true);
      try {
        let ep= `${BASE_URL}/web-sdk/api/users/wallet/upi_bank_account?page=1&per_page=10&from_date=${startDate}&to_date=${endDate}&theme=#c12424`
        const searchParams = new URLSearchParams(ep.substring(ep.indexOf('?')));
        const response = await fetch(
        // &company_name=&from_date=&to_date=
         ep,
          {
            method: "GET",
            headers: {
              "X-Token": getTokens()?.token,
              "X-Access-Token": getTokens()?.pid,
            },
          }
        );
        const json = await response.json();
        setFilterBy(json.transactions);
          setPeriod(json);
        console.log(period, "ok");
        // setDate(json.wallet_data.balance);
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    };

    return (
        <>
            <section className="wallet-cash-wrapper">
                <div className="container">
                    <div className="product-filter">
                        <Link  to="#" className="btn-style medium transparent gray blue-bg icon"
                               onClick={filterOpen}>Filter
                            <img src={`${filterByIcon}`} alt="Product"/>
                        </Link>
                    </div>
                </div>

                <div className="month-card-wrapper">
                    <div className="card-heading">
                        <div className="container">
                            {/* <h3>January 2023 </h3> */}
                        </div>
                    </div>
                    <div className="card-body ">
                        <div className="container">
                            {isLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <>
                                    {filterBy &&
                                        filterBy.userRewards.map((item, i) => (
                                            <div className="row">
                                                <div className="col-8 align-self-center">
                                                    <div className="right-content-wrapper">
                                                        <div className="img-wrapper">
                                                            <img src={`${availableProductImg1}`} alt="Product"/>
                                                        </div>
                                                        <div className="content-wrapper">
                                                            <span className="order-id">#{item.metadata.order_id}</span>
                                                            <p>{item.message }{ !item.is_available && item.transaction_type==="credit"?" (Pending)":""} </p>
                                                            <p>{moment.utc(item.created_at).tz('Asia/Kolkata').format('lll')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4 align-self-center text-end">
                                                    <div className="left-content-wrapper">
                                                        <div className="content-wrapper">
                                                            <h3 style={{color: item.transaction_type !== "debit" ? '#6BBE4D':'red'}}>₹ {item.value}</h3>
                                                            <p>{item.metadata.scheme_id}</p>
                                                        </div>
                                                        <div className="img-wrapper" style={{backgroundColor: item.transaction_type !== "debit" ? 'rgba(0, 188, 64, 0.24)':'rgba(255,0,0, .2)'}}>
                                                            {(item.transaction_type !== "debit") &&
                                                                <img src={`${balanceIcon}`} alt="Product"/>
                                                            }
                                                            {(item.transaction_type === "debit") &&
                                                                <img src={`${balanceIconRed}`} alt="Product"/>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </section>

            <Modal show={showFilter} centered onHide={filterClose} className="filter-popup">
                <Modal.Header>
                    <Link to="#" className="btn-style black medium transparent text icon">Filter
                        <img src={`${filterByIcon}`} alt="Product"/>
                    </Link>
                    <Link to="#" className="close-popup" onClick={filterClose}>
                        Close
                        <img src={`${popupCloseIcon}`} alt="Icon"/>
                    </Link>
                </Modal.Header>
                <Modal.Body>
                    <div className="filter-body-wrapper">
                        <Tab.Container defaultActiveKey="first">
                            <div className="filter-tabs-wrapper">
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Period</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Custom</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="form">
                                            <div className="form-group checkbox">
                                                <input type="checkbox" id="this-month"/>
                                                <label htmlFor="this-month">This Month</label>
                                            </div>
                                            <div className="form-group checkbox">
                                                <input type="checkbox" id="last-month"/>
                                                <label htmlFor="last-month">Last Month</label>
                                            </div>
                                            <div className="form-group checkbox">
                                                <input type="checkbox" id="last-quarter"/>
                                                <label htmlFor="last-quarter">Last Quarter</label>
                                            </div>
                                            <div className="form-group checkbox">
                                                <input type="checkbox" id="six-month"/>
                                                <label htmlFor="six-month">This 6 Month</label>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="form">
                                            <div className="form-group date">
                                                <label htmlFor="from-date">From Date</label>
                                                <input id="from-date" type="date"
                                                       onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group date">
                                                <label htmlFor="from-date">To Date</label>
                                                <input id="to-date" type="date"
                                                       onChange={(e) => setEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Link to="#" className="btn-style" onClick={(e) => {getValue(e)}}>Apply</Link>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const DecMonthCardWrapper = (props) => {


    return (
        <>
            <div className="month-card-wrapper">
                <div className="card-heading">
                    <div className="container">
                        <h3>December 2022 </h3>
                    </div>
                </div>
                <div className="card-body ">
                    <div className="container">
                        <div className="row">
                            <div className="col-8 align-self-center">
                                <div className="right-content-wrapper">
                                    <div className="img-wrapper">
                                        <img src={`${availableProductImg1}`} alt="Product"/>
                                    </div>
                                    <div className="content-wrapper">
                                        <p>For Payment on Pharmarack</p>
                                        <p>Jan 06, 2023</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 align-self-center text-end">
                                <div className="left-content-wrapper">
                                    <div className="content-wrapper">
                                        <h3>₹26</h3>
                                        <p>06 January</p>
                                    </div>
                                    <div className="img-wrapper">
                                        <img src={`${balanceIcon}`} alt="Product"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 align-self-center">
                                <div className="right-content-wrapper">
                                    <div className="img-wrapper">
                                        <img src={`${availableProductImg1}`} alt="Product"/>
                                    </div>
                                    <div className="content-wrapper">
                                        <p>For Payment on Pharmarack</p>
                                        <p>Jan 06, 2023</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 align-self-center text-end">
                                <div className="left-content-wrapper">
                                    <div className="content-wrapper">
                                        <h3>₹26</h3>
                                        <p>06 January</p>
                                    </div>
                                    <div className="img-wrapper">
                                        <img src={`${balanceIcon}`} alt="Product"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 align-self-center">
                                <div className="right-content-wrapper">
                                    <div className="img-wrapper">
                                        <img src={`${availableProductImg1}`} alt="Product"/>
                                    </div>
                                    <div className="content-wrapper">
                                        <p>For Payment on Pharmarack</p>
                                        <p>Jan 06, 2023</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 align-self-center text-end">
                                <div className="left-content-wrapper">
                                    <div className="content-wrapper">
                                        <h3>₹26</h3>
                                        <p>06 January</p>
                                    </div>
                                    <div className="img-wrapper">
                                        <img src={`${balanceIcon}`} alt="Product"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

const OctMonthCardWrapper = (props) => {


    return (
        <>

            <div className="month-card-wrapper">
                <div className="card-heading">
                    <div className="container">
                        <h3>October 2022 </h3>
                    </div>
                </div>
                <div className="card-body ">
                    <div className="container">
                        <div className="row">
                            <div className="col-8 align-self-center">
                                <div className="right-content-wrapper">
                                    <div className="img-wrapper">
                                        <img src={`${availableProductImg1}`} alt="Product"/>
                                    </div>
                                    <div className="content-wrapper">
                                        <p>For Payment on Pharmarack</p>
                                        <p>Jan 06, 2023</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 align-self-center text-end">
                                <div className="left-content-wrapper">
                                    <div className="content-wrapper">
                                        <h3>₹26</h3>
                                        <p>06 January</p>
                                    </div>
                                    <div className="img-wrapper">
                                        <img src={`${balanceIcon}`} alt="Product"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 align-self-center">
                                <div className="right-content-wrapper">
                                    <div className="img-wrapper">
                                        <img src={`${availableProductImg1}`} alt="Product"/>
                                    </div>
                                    <div className="content-wrapper">
                                        <p>For Payment on Pharmarack</p>
                                        <p>Jan 06, 2023</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 align-self-center text-end">
                                <div className="left-content-wrapper">
                                    <div className="content-wrapper">
                                        <h3>₹26</h3>
                                        <p>06 January</p>
                                    </div>
                                    <div className="img-wrapper">
                                        <img src={`${balanceIcon}`} alt="Product"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 align-self-center">
                                <div className="right-content-wrapper">
                                    <div className="img-wrapper">
                                        <img src={`${availableProductImg1}`} alt="Product"/>
                                    </div>
                                    <div className="content-wrapper">
                                        <p>For Payment on Pharmarack</p>
                                        <p>Jan 06, 2023</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 align-self-center text-end">
                                <div className="left-content-wrapper">
                                    <div className="content-wrapper">
                                        <h3>₹26</h3>
                                        <p>06 January</p>
                                    </div>
                                    <div className="img-wrapper">
                                        <img src={`${balanceIcon}`} alt="Product"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {JanMonthCardWrapper, DecMonthCardWrapper, OctMonthCardWrapper};
