import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import backIcon from "../assets/images/icons/back-icon.svg";
import cashCardIcon from "../assets/images/icons/cash-icon.svg";
import coinsIcon from "../assets/images/icons/coins-icon.svg";
import giftIcon from "../assets/images/icons/gift-card-icon.svg";
import certificateIcon from "../assets/images/icons/certificate-icon.svg";
import scratchIcon from "../assets/images/icons/scratch-card-icon.svg";
import notifications from "../assets/images/icons/notification-bell-icon.svg";
import walletIcon from "../assets/images/icons/wallet-white-icon.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BASE_URL, WEB_URL, MOBILE_URL} from "../Constants";
import balanceIcon from "../assets/images/icons/balance-wallet-icon.svg";
import { getTokens } from "../helpers/helpers";

const Header = (props) => {
    let navigate = useNavigate();
    var headerOptions = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        rows: 2,
        slidesPerRow: 3
    };
    const [wallet, setWallet] = useState("");
    useEffect(() => {
        getWallet();
    }, []);
    const [disBack, setDisBack] = useState(false)
    const getWallet = async () => {
        try {
            let ep = `${BASE_URL}/web-sdk/api/users/wallet?theme=#c12424`
            const searchParams = new URLSearchParams(ep.substring(ep.indexOf('?')));
            const response = await fetch(
                ep,
                {
                    method: "GET",
                    headers: {
                        "X-Token": getTokens()?.token,
                        "X-Access-Token": getTokens()?.pid,
                    },
                }
            );
            const json = await response.json();
            console.log(json.data.wallet, "ok header wallet");
            console.log(json, "ok header wallet");
            setWallet(json)
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        if (window.location.pathname === "/") {
            setDisBack(true)
        } else {
            setDisBack(false)
        }
    }, [])

    return (
        <>
            <header>
                <div className="container">
                    <div className="top-header">
                        <div className="row">
                            <div className="col-6 align-self-center">

                                <div className="page-name">
                                    {disBack ? '' : (
                                        <img src={`${backIcon}`} alt="Previous Page" onClick={() => navigate(-1)} />
                                    )}
                                    <h3 className="white">{props.pageTitle}</h3>
                                </div>
                            </div>
                            <div className="col-6 align-self-center text-end">
                                <div className="btn-wrapper">
                                    <Link to="/tds-report" className="btn-style small dark-blue">TDS Report</Link>
                                    <Link to="/support" className="btn-style small light-blue">Support</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-wrapper">
                        <div className="row">
                            <div className="col-8 align-self-center">
                                <Slider {...headerOptions}>

                                    {wallet &&
                                        wallet.data.wallet.map((item, i) => (
                                            <div className="earned-card">
                                                <Link to="/cash-rewards" className="total-earning">
                                                    <img src={`${cashCardIcon}`} alt="Cash" />
                                                    <span>{(Math.round(item.balance * 100) / 100).toFixed(2)}</span>
                                                </Link>
                                                <p className="white">Cash</p>
                                            </div>
                                        ))}
                                    {/* <div className="earned-card">
                                        <Link to="/gift-cards" className="total-earning">
                                            <img src={`${coinsIcon}`} alt="Coins"/>
                                            <span>14</span>
                                        </Link>
                                        <p className="white">Coins</p>
                                    </div>
                                    <div className="earned-card">
                                        <Link to="/gift-cards" className="total-earning">
                                            <img src={`${giftIcon}`} alt="My Gift Cards"/>
                                            <span>2</span>
                                        </Link>
                                        <p className="white">My Gift Cards</p>
                                    </div>

                                    <div className="earned-card">
                                        <Link to="#" className="total-earning">
                                            <img src={`${certificateIcon}`} alt="Certificates"/>
                                            <span>14</span>
                                        </Link>
                                        <p className="white">Certificates</p>
                                    </div>
                                    <div className="earned-card">
                                        <Link to="/card-scratch" className="total-earning">
                                            <img src={`${scratchIcon}`} alt="Scratch Card"/>
                                            <span>14</span>
                                        </Link>
                                        <p className="white">Scratch Card</p>
                                    </div>*/}
                                </Slider>
                            </div>
                            <div className="col-4 align-self-center">
                                <div className="btn-wrapper">
                                    {/*<Link to="/notification" className="notifications">
                                        <img src={`${notifications}`} alt="Notifications"/>
                                    </Link>*/}
                                    <Link to="/wallet" className="btn-style small icon white transparent">
                                        <img src={`${walletIcon}`} alt="Wallet" />
                                        Wallet
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

const InnerHeader = (props) => {
    let navigate = useNavigate();
    return (
        <>
            <header className={`inner-header  ${props.class}`}>
                <div className="container">
                    <div className="top-header">
                        <div className="row">
                            <div className="col-8 align-self-center">
                                <div className="page-name">
                                    <img src={require(`../assets/images/icons/${props.backIcon}`)}
                                        alt="Previous Page" onClick={() => navigate(-1)} />
                                    <h3 className="white">{props.pageTitle}</h3>
                                </div>
                            </div>
                            <div className="col-4 align-self-center text-end">
                                <Link to={props.nextPage} className="label">{props.pageLabel}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

const CoinHeader = (props) => {
    let navigate = useNavigate();
    return (
        <>
            <header className={`coin-header  ${props.class}`}>
                <div className="container">
                    <div className="top-header">
                        <div className="row">
                            <div className="col-7 align-self-center">
                                <div className="page-name">
                                    <img src={require(`../assets/images/icons/${props.backIcon}`)} alt="Previous Page" onClick={() => navigate(-1)} />
                                    <h3 className="white">{props.pageTitle}</h3>
                                </div>
                            </div>
                            <div className="col-5 align-self-center text-end">
                                <Link to="#" className="btn-style small light-blue icon rounded px-3">
                                    <img src={`${coinsIcon}`} alt="Notifications" />
                                    {props.buttonLabel}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

const WalletHeader = (props) => {
    console.log({ props })
    let navigate = useNavigate();
    return (
        <>
            <header className={`inner-header  ${props.class}`}>
                <div className="container">
                    <div className="top-header">
                        <div className="row">
                            <div className="col-6 align-self-center">
                                <div className="page-name">
                                    <img src={require(`../assets/images/icons/${props.backIcon}`)} alt="Previous Page" onClick={() => navigate(-1)} />
                                    <h3 className="white">{props.pageTitle}</h3>
                                </div>
                            </div>
                            <div className="col-6 align-self-center text-end">
                                <div className="btn-wrapper">
                                    <Link to={`/${props.walletLink}`} className="btn-style small green white" >Redeem</Link>
                                    <Link to="/support" className="btn-style small light-blue">Support</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-wrapper">
                        <div className="row">
                            <div className="col-8 align-self-center">
                                <h3 className="white"><strong>{props.pageTitle1}</strong></h3>
                                <h3 className="white">{props.pageTitle2}</h3>
                            </div>
                            <div className="col-4 align-self-center">
                                <div className="btn-wrapper">
                                    <Link to="/notification" className="notifications">
                                        <img src={`${notifications}`} alt="Notifications" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

const BrandHeader = (props) => {
    let navigate = useNavigate();
    return (
        <>
            <header className="brand-header">
                <div className="container">
                    <div className="top-header">
                        <div className="row">
                            <div className="col-12 align-self-center">
                                <div className="page-name">
                                    <img src={`${backIcon}`} alt="Previous Page" onClick={() => navigate(-1)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-wrapper">
                        <div className="row">
                            <div className="col-8 align-self-center">
                                <div className="content-wrapper">
                                    <h3 className="white">{props.pageTitle}</h3>
                                    <p className="white">{props.pageDes}</p>
                                </div>
                            </div>
                            <div className="col-4 align-self-center">
                                <img src={require(`../assets/images/${props.image}`)} alt="pharamarak" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}


export { Header, InnerHeader, WalletHeader, BrandHeader, CoinHeader };
