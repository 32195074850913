import { Link } from "react-router-dom";
import React from 'react';


import {BrandHeader} from "../components/Header";
import {TermsCondition} from "../components/TermsCondition";
import Footer from "../components/Footer";
import schemaProductImg from "../assets/images/schema-product-img.png";
function BrandOfferDetail() {

    return (
        <>
            <BrandHeader pageTitle="Xapilis Tablets" pageDes="Hurry Up.!! Valid till 31st Jan 2023" image="schema-header-img.png"/>

            <section className="schema-product">
                <div className="container">
                    <div className="product-img">
                        <img src={`${schemaProductImg}`} alt="schema"/>
                    </div>
                    <ul className="product-price">
                        <li><h3>Mrp : ₹ 250</h3></li>
                        <li><h3>PTR : ₹ 225</h3></li>
                    </ul>
                    <div className="variant-wrapper">
                        <p className="p">SKU Variant: </p>
                        <ul className="variant">
                            <li><Link to="#" className="btn-style medium">Xapilis 2.5 mg</Link></li>
                            <li><Link to="#" className="btn-style medium">Xapilis 5 mg</Link></li>
                            <li><Link to="#" className="btn-style medium">Xapilis 10 mg</Link></li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="schema-offer-track">
                <div className="container">
                    <h3>Offer Tracker</h3>
                    <ul className="offer-track">
                        <li>
                            <span>5</span>
                            <div className="content-wrapper">
                                <h3>3.0% Cashback</h3>
                                <p>Buy min 5 qty to be eligible for this offer</p>
                            </div>
                        </li>
                        <li>
                            <span>10</span>
                            <div className="content-wrapper">
                                <h3>5.0% Cashback</h3>
                                <p>Buy 10 qty to get flat 5.0% Cashback</p>
                            </div>
                        </li>
                        <li>
                            <span>163</span>
                            <div className="content-wrapper">
                                <h3>Max Benefit - ₹250</h3>
                                <p>Buy 10 qty to get flat 5.0% Cashback</p>
                            </div>
                        </li>
                    </ul>
                    <div className="terms-condition">
                        <TermsCondition/>
                    </div>
                    <Link to="#" className="btn-style order-btn full">ORDER NOW</Link>
                </div>
            </section>

            <Footer/>

        </>
    );
}

export default BrandOfferDetail;
