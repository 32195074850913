import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import FileUploadIcon from "../assets/images/icons/file-upload-icon.svg";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";
import FileSuccessIcon from "../assets/images/icons/upload-success-icon.svg";
import { BASE_URL, WEB_URL, MOBILE_URL} from "../Constants";
import { set, useForm } from "react-hook-form";
import { Button } from "bootstrap/dist/js/bootstrap.bundle";
import { getTokens } from "../helpers/helpers";
const UploadInvoice = ({ orderId }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState();
  const [showUpload, setShowUpload] = useState(false);
  const [resp, setRes] = useState();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setError] = useState(false);
  const uploadClose = () => setShowUpload(false);
  const uploadOpen = () => setShowUpload(true);
  const SuccessOpen = () => setShowSuccess(true);
  const ErrorClose = () => setError(false);
  const SuccessClose = (e) => {
    console.log(e);
    navigate('/order-history')
    setShowSuccess(false);
  };
  const { register, handleSubmit } = useForm();

  const onSubmit = async (fetcheddata) => {
    function getExtension(filename) {
      return filename.file[0].name.split('.').pop()
    }
    const ext = getExtension(fetcheddata)
    if (ext == "jpg" || ext == "jpeg" || ext == "pdf" || ext == "png") {
      let data = new FormData();
      data.append("file", fetcheddata.file[0]);
      data.append("type", "company");
      let ep = `${BASE_URL}/web-sdk/api/flyy_images?theme=#c12424`
      const searchParams = new URLSearchParams(ep.substring(ep.indexOf('?')));
      const res = await fetch(ep, {
        method: "POST",
        headers: {
          "X-Token": getTokens()?.token,
          "X-Access-Token": getTokens()?.pid,
        },

        body: data,
      });
      // console.log(await res.json());
      await res.json().then((res) => {
        const dispute = createDispute(res.url);
      });
    } else {
      setError(true)
    }
    //alert(JSON.stringify(`${res.message}, status: ${res.status}`));
  };

  async function createDispute(invoiceurl) {
    try {
      let ep = `${BASE_URL}/web-sdk/api/disputes?from_date=&to_date&theme=#c12424`

      const searchParams = new URLSearchParams(ep.substring(ep.indexOf('?')));
      const response = await fetch(ep, {
        method: "POST",

        headers: {
          "X-Token": getTokens()?.token,
          "Content-Type": "application/json",
          "X-Access-Token": getTokens()?.pid,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          dispute: {
            order_id: orderId,
            invoice_url: invoiceurl,

          },
        }),
      });
      await response
        .json()
        .then((res) => {
          console.log(res, "ok");
          setRes(res)
          setShowSuccess(true);
          setShowUpload(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <>
      <div className="invoice-wrapper text-center">
        <h3>Need Help ? Upload your Invoice</h3>
        <Link to="#" className="btn-style" onClick={uploadOpen}>
          UPLOAD INVOICE
        </Link>
      </div>

      <Modal
        show={showUpload}
        centered
        onHide={uploadClose}
        className="invoice-popup"
      >
        <Modal.Header>
          <h3>Upload Invoice</h3>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="invoice-body-wrapper text-center">
              <div className="file-upload">
                <img src={`${FileUploadIcon}`} alt="file-upload" />
                <label
                  htmlFor="local-file-upload"
                  className="btn-style bg-gray"
                >
                  <input
                    type="file"
                    id="local-file-upload"
                    {...register("file")}
                  />
                  Browse or Upload
                </label>
                <p>
                  Kindly Upload Photo(s),PDF(s)
                  <br />
                  <span>Size Upto : 40MB</span>
                </p>
              </div>
              <input
                type="submit"
                className="btn-style"
                value="Submit Invoice"
              />
              {/* <Link to="#" className="btn-style" onClick={(e) => {
                           
                            uploadClose();
                   
                            // setTimeout(function () {
                            //     navigate("/ticket-history")
                            // }, 1500);

                        }}>Submit Invoice</Link> */}
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSuccess}
        centered
        onHide={SuccessClose}
        className="success-popup"
      >
        <Modal.Header>
          <div className="close-popup" onClick={(e) => { SuccessClose(e) }}>
            Close
            <img src={`${popupCloseIcon}`} alt="Icon" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="success-body-wrapper text-center">
            <img src={`${FileSuccessIcon}`} alt="Success-upload" />
            <h3>Uploaded Successfully</h3>
            <p>
              Your Ticket ID : {resp?.dispute?.dispute_id}. Our support team <br /> will connect with
              you shortly.
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showError}
        centered
        onHide={ErrorClose}
        className="success-popup"
      >
        <Modal.Header>
          <div className="close-popup" onClick={(e) => { ErrorClose(e) }}>
            Close
            <img src={`${popupCloseIcon}`} alt="Icon" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="success-body-wrapper text-center">
            <h3>File Type Not Allowed</h3>
            <p>
              Please Upload an Image or a PDF
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { UploadInvoice };

