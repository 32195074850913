import { Link } from "react-router-dom";
import companyDealImg1 from "../assets/images/company-deals-img-1.png";
import companyDealImg2 from "../assets/images/company-deals-img-2.png";
import companyDealImg3 from "../assets/images/company-deals-img-3.png";
import companyDealImg4 from "../assets/images/company-deals-img-4.png";
import companyDealImg5 from "../assets/images/company-deals-img-5.png";
import React, {useState,useEffect} from "react";

const ProductDeal = ({data}) => {
 
  return (
    <>
      <ul  className="deal">
        {data?.map((deals, index) => (
          <li>
            <Link to="/cashback-deal">
              <div className="img-wrapper">
                <img src={`${companyDealImg1}`} alt="Banner Slider" />
              </div>
              <div className="content-wrapper">
                                <span className="medium">{deals.title}</span>
                        </div>
            </Link>
          </li>
        ))}
      
      </ul>
    </>
  );
};

export { ProductDeal };
{/* <li>
<Link to="/cashback-deal">
  <div className="img-wrapper">
    <img src={`${companyDealImg1}`} alt="Banner Slider" />
  </div>
  <div className="content-wrapper">
    <span className="medium">
      Cashback <br />
      Deals
    </span>
  </div>
</Link>
</li>

<li>
<Link to="/loyalty-deals">
  <div className="img-wrapper">
    <img src={`${companyDealImg2}`} alt="Banner Slider" />
  </div>
  <div className="content-wrapper">
    <span className="medium">
      Loyalty
      <br />
      Deals
    </span>
  </div>
</Link>
</li>

<li>
<Link to="">
  <div className="img-wrapper">
    <img src={`${companyDealImg3}`} alt="Banner Slider" />
  </div>
  <div className="content-wrapper">
    <span className="medium">
      Watch &
      <br />
      Earn
    </span>
  </div>
</Link>
</li>

<li>
<Link to="/refer-earn">
  <div className="img-wrapper">
    <img src={`${companyDealImg4}`} alt="Banner Slider" />
  </div>
  <div className="content-wrapper">
    <span className="medium">
      Refer &
      <br />
      Earn
    </span>
  </div>
</Link>
</li>

<li>
<Link to="">
  <div className="img-wrapper">
    <img src={`${companyDealImg5}`} alt="Banner Slider" />
  </div>
  <div className="content-wrapper">
    <span className="medium">
      Wheel Of
      <br />
      Fortune
    </span>
  </div>
</Link>
</li> */}