import whatsAppIcon from "../assets/images/icons/whatsapp-green-icon.svg";
import facebookMessengerIcon from "../assets/images/icons/facebook-messenger-logo-icon.svg";
import insertLinkIcon from "../assets/images/icons/insert-link-icon.svg";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./loading";

const SocialLinks = (props) => {

    return (
        <>
            <div className="social-links">
                <div className="row">
                    <div className="col-4 align-self-center text-center">
                        <Link to="/" className="img-wrapper">
                            <img src={`${whatsAppIcon}`} alt="Banner Slider" />
                        </Link>
                    </div>
                    <div className="col-4 align-self-center text-center">
                        <Link to="#" className="img-wrapper">
                            <img src={`${facebookMessengerIcon}`} alt="Banner Slider" />
                        </Link>
                    </div>
                    <div className="col-4 align-self-center text-center">
                        <Link to="#" className="img-wrapper">
                            <img src={`${insertLinkIcon}`} alt="Banner Slider" />
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}


const HowItWorks = (props) => {

    return (
        <>
            <div className="how-it-works">
                <div className="container">
                    <h3>How it works ?</h3>
                    <ul>
                        <li>
                            <span>1</span>
                            <p>Invite your friends and get rewarded</p>
                        </li>
                        <li>
                            <span>2</span>
                            <p>They ₹300 toward their first service after clicking
                                the invitation link</p>
                        </li>
                        <li>
                            <span>3</span>
                            <p>You get ₹300 after they complete the service.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

const OfferTracker = ({ data }) => {

    const [datas, setDatas] = useState()
    const [endEntry, setEnd] = useState('')

    useEffect(() => {
        if (data?.rewards) {
          setEnd(data.rewards[data.rewards.length - 1])
        }
      }, [])

    const [isLoading, setIsLoading] = useState(false);
    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (

                <ul className="offer-track">
                    {data &&
                        data.rewards.map((item, i) => (
                            <>
                                <li>
                                    <span>{item.min}</span>
                                    <div className="content-wrapper">
                                        <h3>{item.reward_type === "percent" ? "" : "₹"}{item.reward_value}{item.reward_type === "percent" ? "%" : ""} Cashback</h3>
                                        <p>Buy min {data.reward_on === "amount" ? "₹" : ""}{item?.min} {data.reward_on === "amount" ? "value" : "quantity"} to be eligible for this offer</p>
                                    </div>
                                </li>


                            </>
                        ))}
                    {endEntry.length!=='' || endEntry!==undefined?(
                        <li>
                            <span>MAX</span>
                            <div className="content-wrapper">
                                <h3>Max Benefit:- ₹ {endEntry?.max_reward}</h3>
                                <p>Buy min {data.reward_on === "amount" ? "₹" : ""}{endEntry?.min} {data.reward_on === "amount" ? "value" : "quantity"} to be eligible for this offer</p>
                            </div>
                        </li>):''
                    }



                </ul>
            )}
        </>
    )
}

export { SocialLinks, HowItWorks, OfferTracker };
