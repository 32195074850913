import React from 'react';
import {Header} from "../components/Header";
import Footer from "../components/Footer";
import referalGift from "../assets/images/icons/refrel-gift-icon.svg";
import {HowItWorks, SocialLinks} from "../components/SocialLinks";

function ReferEarn() {

    return (
        <>
            <Header pageTitle="Cashback Deals"/>
            <section className="refer-earn-wrapper">
                <div className="refer-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-9 align-self-center">
                                <div className="content-wrapper">
                                    <h3>Refer and Get Rewards</h3>
                                    <p>Invite your friends to try Pharmarack Retailer<br/>
                                        App services. They get instant ₹300 off.</p>
                                </div>
                            </div>
                            <div className="col-3 text-center align-self-center">
                                <div className="image-wrapper">
                                    <img src={`${referalGift}`} alt="Banner Slider"/>
                                </div>
                            </div>
                        </div>

                        <div className="refer-text text-center">
                            <p>Refer via</p>
                        </div>

                        <SocialLinks/>
                    </div>
                </div>

                <HowItWorks/>
            </section>

            <Footer/>

        </>
    );
}

export default ReferEarn;
