import React,{useState} from 'react';

import {InnerHeader} from "../components/Header";
import Footer from "../components/Footer";
import {PeriodFilter} from "../components/PeriodFilter";
import {RecentInvoiceTicket} from "../components/RecentInvoiceTicket";

function TicketHistory() {

    const[dates,setDates]=useState(["",""])
    return (
        <>
            <InnerHeader pageTitle="Ticket History" backIcon="back-icon-black.svg" class="transparent-header"/>

            <section className="recent-order">
                <div className="container">
                    <div className="sort-filter">
                        < PeriodFilter setDates={setDates}/>
                    </div>
                    <RecentInvoiceTicket dates={dates}/>
                </div>
            </section>

            <Footer/>

        </>
    );
}

export default TicketHistory;
