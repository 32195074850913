import {Link} from "react-router-dom";
import balanceIcon from "../assets/images/icons/balance-wallet-icon.svg";
import React from "react";


const DayStepDeal = (props) => {

    return (
        <>
            <div className="day-steps-wrapper">
                <ul>
                    <li>
                        <Link to="#" className="active">
                            <div className="img-wrapper">
                            </div>
                            <p>Day 1</p>
                        </Link>
                    </li>
                    <li>
                        <Link to="#" className="active">
                            <div className="img-wrapper">
                            </div>
                            <p>Day 2</p>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <div className="img-wrapper">
                            </div>
                            <p>Day 3</p>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            <div className="img-wrapper icon">
                                <img src={`${balanceIcon}`} alt="Product"/>
                            </div>
                            <p>Earn</p>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

export {DayStepDeal};
