import React from 'react';

import {Header} from "../components/Header";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

import loyaltyDealsImage from "../assets/images/loyalty-deals-main-image.png";
import completeCheck from "../assets/images/icons/complete-check-icon.svg";
import balanceIcon from "../assets/images/icons/balance-wallet-icon.svg";
import {DayStepDeal} from "../components/DayStepDeal";

function LoyaltyDeals() {

    return (
        <>
            <Header pageTitle="Loyalty Deals"/>
            <section className="loyalty-deals-wrapper">
                <div className="container">
                    <div className="loyalty-deals-banner text-center">
                        <img src={`${completeCheck}`} alt="Done"/>
                        <h1>Order Everyday To Win Cashback <br/>
                            Everyday Place Order of Rs. 1000 & <br/>
                            Above To Win Cashback on Every...
                        </h1>

                        <div className="floating-wrapper">
                            <img src={`${balanceIcon}`} alt="Product"/>
                            <p>Earn Cash</p>
                        </div>
                    </div>
                    <DayStepDeal/>
                    <div className="testing-wrapper text-center">
                        <img src={`${completeCheck}`} alt="Done"/>
                        <h1>Testing</h1>
                        <div className="floating-wrapper">
                            <img src={`${balanceIcon}`} alt="Product"/>
                            <p>Earn Cash</p>
                        </div>
                    </div>
                    <Link to="/reward-details" className="loyalty-deals-image-wrapper">
                        <img src={`${loyaltyDealsImage}`} alt="Product"/>
                    </Link>
                </div>
            </section>
            <Footer/>
        </>
    )
        ;
}

export default LoyaltyDeals;
