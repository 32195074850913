import { useEffect, useState } from "react";

const PercentFilter = ({ selectedPercent, setSelectedPercent }) => {
  const handleButtonClick = (percentRange) => {
    setSelectedPercent(percentRange);
  };
  useEffect(() => {
    console.log(selectedPercent)
  })
  return (
    <div className="percent-filter">
      <button
        onClick={() => handleButtonClick([2, 5])}
        style={{
          background:
            selectedPercent[0] === 2 ? "orange" : "white", // Apply red background if selected
          // Add other styles as needed
          color:
            selectedPercent[0] === 2 ? "white" : "orange", // Apply red background if selected
          // Add other styles as needed
        }}
        className="btn-style small transparent rounded"
      >
        3% - 5%
      </button>
      <button
        onClick={() => handleButtonClick([6, 10])}
        style={{
          background:
            selectedPercent[0] === 6 ? "orange" : "white", // Apply red background if selected
          // Add other styles as needed
          color:
            selectedPercent[0] === 6 ? "white" : "orange", // Apply red background if selected
          // Add other styles as needed
        }}
        className="btn-style small transparent rounded"
      >
        6% - 10%
      </button>
      <button
        onClick={() => handleButtonClick([11, 16])}
        style={{
          background:
            selectedPercent[0] === 11 ? "orange" : "white", // Apply red background if selected
          // Add other styles as needed
          color:
            selectedPercent[0] === 11 ? "white" : "orange", // Apply red background if selected
          // Add other styles as needed
        }}
        className="btn-style small transparent rounded"
      >
        11% - 15%
      </button>
      <button
        onClick={() => handleButtonClick([0, 100])}
        style={{
          background:
            selectedPercent[0] === 0 ? "orange" : "white", // Apply red background if selected
          // Add other styles as needed
          color:
            selectedPercent[0] === 0 ? "white" : "orange", // Apply red background if selected
          // Add other styles as needed
        }}
        className="btn-style small transparent rounded"
      >
        All
      </button>
    </div>
  );
};
export { PercentFilter }
