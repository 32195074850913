import React from 'react';


import {InnerHeader} from "../components/Header";
import Footer from "../components/Footer";
import coinsIcon from "../assets/images/icons/coins-icon.svg";
import {CoinGiftCard} from "../components/CoinGiftCard";

function GiftCards() {


    return (
        <>
            <InnerHeader pageTitle="Coins" backIcon="left-arrow-icon.svg" pageLabel="My Gift Cards"
                         class="gift-card-header" nextPage="/my-gift-card"/>

            <section className="gift-cards-wrapper">
                <div className="container">
                    <div className="gift-card-banner">
                        <div className="img-wrapper">
                            <img src={`${coinsIcon}`} alt="Product"/>
                        </div>
                        <div className="content-wrapper">
                            <h3>Total</h3>
                            <strong>375</strong>
                        </div>
                    </div>
                </div>
                <CoinGiftCard/>
            </section>

            <Footer/>


        </>
    );
}

export default GiftCards;
