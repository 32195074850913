import {Link, useNavigate} from "react-router-dom";
import eyeIcon from "../assets/images/icons/eye-icon.svg";
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import FileUploadIcon from "../assets/images/icons/file-upload-icon.svg";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";
import FileSuccessIcon from "../assets/images/icons/upload-success-icon.svg";
import {BASE_URL} from "../Constants";
import LoadingSpinner from "./loading";
import Moment from "react-moment";
// import {PDFtoIMG} from 'react-pdf-to-image';
import {getTokens } from "../helpers/helpers";
const RecentInvoiceTicket = ({dates}) => {
    const [showUpload, setShowUpload] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const navigate = useNavigate();
    const uploadClose = () => setShowUpload(false);
    const uploadOpen = () => setShowUpload(true);
    const SuccessOpen = () => setShowSuccess(true);
    const SuccessClose = () => setShowSuccess(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ticket, setTicket] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [certificateImg, setcertificateImg] = useState('');
    const filterClose = () => setShowFilter(false);
    function filterOpen(e, img){
        e.preventDefault();
        setShowFilter(true);
        console.log(img, "ook")
        setcertificateImg(img)
    }
    useEffect(() => {
        getTicketHistory();
    }, [ticket.length,dates]);
    const getTicketHistory = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL}/web-sdk/api/disputes?page=1&per_page=10&from_date=${dates[0]}&to_date=${dates[1]}&theme=#c12424`, {
                method: 'GET',
                headers: {
                    "X-Token": getTokens()?.token,
                   "X-Access-Token": getTokens()?.pid,
                 },
            });
            const json = await response.json();
            setTicket(json)
            console.log(ticket, "ok");
            setIsLoading(false)
        } catch (error) {
            console.log("error", error);
            setIsLoading(false)
        }
    };
    return (
        <>
            {isLoading ? <LoadingSpinner/> :
                <div className="order-ticket-wrapper">
                    {
                        ticket && ticket?.disputes?.disputes?.map((item, i) => (
                            <Link to={`/ticket-single/${item.dispute_id}`} className="recent-invoice-ticket" key={i}>
                                <Modal show={showFilter} centered onHide={filterClose} className="filter-popup">
                                    <Modal.Header>
                                        <Link to="#" className="btn-style black medium transparent text icon">Invoice
                                        </Link>
                                        <Link to="#" className="close-popup" onClick={filterClose}>
                                            Close
                                            <img src={`${popupCloseIcon}`} alt="Icon"/>
                                        </Link>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="certificate-body-wrapper">
                                            <div className="img-wrapper">
                                                <img src={`${certificateImg}`} alt="Certificate"/>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <div className="content-wrapper">
                                    <div className="row">
                                        <div className="col-7 align-self-end">
                                            <div className="left-content">
                                                <h3>Ticket No : {item.dispute_id} </h3>
                                                <Link to="#" className="btn-style icon" onClick={(e) => {filterOpen(e, item.invoice_url)}}><img src={`${eyeIcon}`}
                                                                                             alt="invoice"/>Invoice </Link>
                                            </div>
                                        </div>
                                        <div className="col-5 text-center ">
                                            <div className="right-content">
                                                <p>Ticket Raised Date : <br/>
                                                    <Moment format="lll" date={item.created_at}/></p>
                                                {(item.status === "auto")&&
                                                    <div className="badge green">
                                                        <div className="icon-wrapper"></div>
                                                        <span>Approved</span>
                                                    </div>
                                                }
                                                {(item.status === "reject")&&
                                                    <div className="badge red">
                                                        <div className="icon-wrapper"></div>
                                                        <span>Rejected</span>
                                                    </div>
                                                }
                                                {(item.status === "manual")&&
                                                    <div className="badge orange">
                                                        <div className="icon-wrapper"></div>
                                                        <span>In Process</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }
                </div>
            }
            <Modal show={showUpload} centered onHide={uploadClose} className="invoice-popup">
                <Modal.Header>
                    <h3>Upload Invoice</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="invoice-body-wrapper text-center">
                        <label className="file-upload">
                            <img src={`${FileUploadIcon}`} alt="file-upload"/>
                            <input type="file"/>
                            <button className="btn-style bg-gray">Browse or Upload</button>
                            <p>Kindly Upload Photo(s),PDF(s)<br/>
                                <span>Size Upto : 40MB</span></p>
                        </label>
                        <Link to="#" className="btn-style" onClick={(e) => {
                            SuccessOpen();
                            uploadClose();
                            setTimeout(function () {
                                navigate("/ticket-single")
                            }, 1500);
                        }}>Submit Invoice</Link>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showSuccess} centered onHide={SuccessClose} className="success-popup">
                <Modal.Header>
                    <Link to="#" className="close-popup" onClick={SuccessClose}>
                        Close
                        <img src={`${popupCloseIcon}`} alt="Icon"/>
                    </Link>
                </Modal.Header>
                <Modal.Body>
                    <div className="success-body-wrapper text-center">
                        <img src={`${FileSuccessIcon}`} alt="Success-upload"/>
                        <h3>Uploaded Successfully</h3>
                        <p>Your Ticket ID : 145363. Our support team <br/> will connect with you shortly.</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export {RecentInvoiceTicket};
