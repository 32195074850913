import productsDealIcon from "../assets/images/icons/products-deal-icon.svg";
import {Link} from "react-router-dom";
import brandDealIcon from "../assets/images/icons/brand-deal-icon.svg";
import comboDealIcon from "../assets/images/icons/combo-deal-icon.svg";
import React from "react";
import { useNavigate } from "react-router-dom";

const DealFilter = (props) => {

    return (
        <>
            <div className="deal-filter">
                <Link to="/product-offer" className="btn-style medium transparent blue rounded icon">
                    <img src={`${productsDealIcon}`} alt="Product"/>
                    Product Deals</Link>
                {/* <Link to="/brand-offer" className="btn-style medium transparent blue rounded icon">
                    <img src={`${brandDealIcon}`} alt="Product"/>
                    Brand Deals</Link> */}
                <Link to="/combo-offer" className="btn-style medium transparent blue rounded icon">
                    <img src={`${comboDealIcon}`} alt="Product"/>
                    Combo Deals</Link>
            </div>
        </>
    )
}

export {DealFilter};
