import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";
import Tab from "react-bootstrap/Tab";
import { InnerHeader } from "../components/Header";
import Footer from "../components/Footer";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import { RecentOrder } from "../components/RecentOrder";
import { PeriodFilter } from "../components/PeriodFilter";
import filterByIcon from "../assets/images/icons/filter-by-icon.svg";
import { getTokens } from "../helpers/helpers";
import { BASE_URL, WEB_URL, MOBILE_URL} from "../Constants";

function OrderHistory() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterBy, setFilterBy] = useState();
  const [dates, setDates] = useState(["",""]);
  const filterClose = () => setShowFilter(false);
  const filterOpen = () => setShowFilter(true);
  const [showFilter, setShowFilter] = useState(false);
  const [status, setStatus] = useState(false);

  console.log(startDate, endDate);

  useEffect(() => {
    getRecentOrder();

  }, [dates]);

  const getRecentOrder = async () => {


    setShowFilter(false);
    try {
      let ep = `${BASE_URL}/web-sdk/api/orders?page=1&per_page=10&from_date=${dates[0]}&to_date=${dates[1]}&theme=#c12424`;
      const response = await fetch(ep, {
        method: "GET",
        headers: {
          "X-Token": getTokens()?.token,
          "X-Access-Token": getTokens()?.pid,
        },
      });
      const json = await response.json();
      setFilterBy(json.orders);

    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <InnerHeader
        pageTitle="Order  History"
        backIcon="back-icon-black.svg"
        pageLabel="Ticket History"
        class="transparent-header"
        nextPage="/ticket-history"
      />
      <section className="recent-order">
        <div className="container">
          <div className="sort-filter">
          <PeriodFilter setDates={setDates}/>
          </div>
          <RecentOrder data={filterBy} />
        </div>
      </section>

      <Footer />
    </>
  );
}

export default OrderHistory;
