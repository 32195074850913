import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { InnerHeader } from "../components/Header";
import Footer from "../components/Footer";
// import { OrderDetails } from "../components/TermsCondition";
import { UploadInvoice } from "../components/UploadInvoice";
import Accordion from "react-bootstrap/Accordion";
import {BASE_URL} from "../Constants"
import {getTokens } from "../helpers/helpers";
import 'moment-timezone';

import moment from 'moment';
function OrderDescription() {
  const [order_Id, setOrderId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const filterClose = () => setShowFilter(false);
  const filterOpen = () => setShowFilter(true);
  const { orderId } = useParams();

  const [filterBy, setFilterBy] = useState("");
  useEffect(() => {
    console.log("Call");
    getRecentOrder();
  }, [filterBy.length]);

  const getRecentOrder = async () => {
    setIsLoading(true);
    try {
      let ep=`${BASE_URL}/web-sdk/api/orders/${orderId}?theme=#c12424`
      
      const searchParams = new URLSearchParams(ep.substring(ep.indexOf('?')));
      const response = await fetch(
        ep,
        {
          method: "GET",
          headers: {
             "X-Token": getTokens()?.token,
            "X-Access-Token": getTokens()?.pid,
          },
        }
      );
      const json = await response.json();
      setFilterBy(json.order);
      setIsAvailable(json.order_is_available);
      console.log(json, "ok");
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log(filterBy.order_id);
  return (
    <>
      <InnerHeader
        pageTitle="My Order Details"
        backIcon="back-icon-black.svg"
        pageLabel=" "
        class="transparent-header"
      />

      <section className="recent-order">
        <div className="container">
          <div className="order-details-wrapper">
            <div className="order-details-slot">
              <h3>OrderId</h3>
              <p>{filterBy.order_id}</p>
            </div>
            <div className="order-details-slot">
              <h3>Placed</h3>
              <p>{moment.utc(filterBy.created_at).tz('Asia/Kolkata').format('LLL')}</p>
            </div>
           {/* <div className="order-details-slot">
              <h3>Uploaded</h3>
              <p className="success">{filterBy.updated_at}</p>
            </div>
            <div className="order-details-slot">
              <h3>Processed</h3>
              <p className="success">{filterBy.updated_at}</p>
            </div>*/}
            <div className="order-details-slot">
              <h3>Total Order Amount</h3>
              <p className="fw-bold">₹{filterBy.total_amount}</p>
            </div>
            {/* <div className="order-details-slot">
              <h3>Total Cashback Amount</h3>
              <p className="fw-bold">₹{filterBy.total_cashback}</p>
            </div> */}
          </div>
          <div className="all-topics-wrapper order-details-accordion-wrapper">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h3>Order Details</h3>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="order-details-heading">
                    <ul>
                      <li>
                        <h3>QTY</h3>
                      </li>
                      <li>
                        <h3>Free</h3>
                      </li>
                      <li>
                        <h3>PTR</h3>
                      </li>
                      <li>
                        <h3>Total</h3>
                      </li>
                      <li>
                        <h3>RecdQty</h3>
                      </li>
                    </ul>
                  </div>
                  <div className="order-details-body">
                    {filterBy &&
                      filterBy.products.map((item, i) => (
                        <>
                          <h3 className="item-name" style={{color:"black"}}>{item.name}</h3>
                          <ul>
                            <li>
                              <p>{item.qty}</p>
                            </li>
                            <li>
                              <p>{item.free_qty}</p>
                            </li>
                            <li>
                              <p>{item.ptr}</p>
                            </li>
                            <li>
                              <p>{item.amount}</p>
                            </li>
                            <li>
                              <p>
                                {item.received_qty}+{item.free_qty}
                              </p>
                            </li>
                          </ul>
                        </>
                      ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          {/* comment by pankaj */}
          <UploadInvoice orderId={orderId} />
          {/* {
            isAvailable ?  <UploadInvoice orderId={orderId} /> :""
          } */}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default OrderDescription;
