import filterByIcon from "../assets/images/icons/filter-by-icon.svg";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { RecentOrder } from "./RecentOrder";

const PeriodFilter = ({ setDates }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const filterClose = () => setShowFilter(false);
  const filterOpen = () => setShowFilter(true);
  const [showFilter, setShowFilter] = useState(false);
  function getValue(e) {
    e.preventDefault();
    const date = [startDate,endDate]
    setDates(date)
    filterClose();
  }

  function getPeriod(e) {
    if (e.target.value === "this month") {
      const first = new Date()
        .toISOString()
        .replace(new Date().toISOString().split("T")[0].split("-")[2], "01")
        .split("T")[0];
      const today = new Date().toISOString().split("T")[0];
      setStartDate(first)
      setEndDate(today)
    } else if (e.target.value === "last month") {
      const year = new Date().getFullYear();
      const month = new Date().getMonth() - 1;
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);

      const st = `${firstDay.getFullYear()}-${(firstDay.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-01`;
      const en = `${lastDay.getFullYear()}-${(lastDay.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${lastDay.getDate().toString().padStart(2, "0")}`;
        setStartDate(st)
        setEndDate(en)
    } else if (e.target.value === "last quarter") {
      const today = new Date();
      const ed = today.toISOString().slice(0, 10); // get today's date in YYYY-MM-DD format
      const startDate = new Date(today.getFullYear(), today.getMonth() - 4, 1); // set start date to first day of 6 months ago
      const startDateString = startDate.toISOString().slice(0, 10); // get start date in YYYY-MM-DD format
      setStartDate(startDateString)
      setEndDate(ed)
    } else if (e.target.value === "six months") {
      const today = new Date();
      const ed = today.toISOString().slice(0, 10); // get today's date in YYYY-MM-DD format
      const startDate = new Date(today.getFullYear(), today.getMonth() - 5, 1); // set start date to first day of 6 months ago
      const startDateString = startDate.toISOString().slice(0, 10); // get start date in YYYY-MM-DD format
      setStartDate(startDateString)
      setEndDate(ed)
    }

    // childToParent(startDate, endDate);
  }

  return (
    <>
      <Link
        to="#"
        className="btn-style medium transparent gray blue-bg icon"
        onClick={filterOpen}
      >
        Filter
        <img src={`${filterByIcon}`} alt="Product" />
      </Link>

      <Modal
        show={showFilter}
        centered
        onHide={filterClose}
        className="filter-popup"
      >
        <Modal.Header>
          <Link to="#" className="btn-style black medium transparent text icon">
            Filter
            <img src={`${filterByIcon}`} alt="Product" />
          </Link>
          <Link to="#" className="close-popup" onClick={filterClose}>
            Close
            <img src={`${popupCloseIcon}`} alt="Icon" />
          </Link>
        </Modal.Header>
        <Modal.Body>
          <div className="filter-body-wrapper">
            <Tab.Container defaultActiveKey="first">
              <div className="filter-tabs-wrapper">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Period</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Custom</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="form">
                      <div
                        onChange={(e) => getPeriod(e)}
                        className="form-group radio"
                      >
                        <input
                          name="period"
                          type="radio"
                          value={"this month"}
                          id="this-month"
                        />
                        <label htmlFor="this-month">This Month</label>
                      </div>
                      <div
                        onChange={(e) => getPeriod(e)}
                        className="form-group radio"
                      >
                        <input
                          name="period"
                          type="radio"
                          id="last-month"
                          value={"last month"}
                        />
                        <label htmlFor="last-month">Last Month</label>
                      </div>
                      <div
                        onChange={(e) => getPeriod(e)}
                        className="form-group radio"
                      >
                        <input
                          name="period"
                          type="radio"
                          id="last-quarter"
                          value={"last quarter"}
                        />
                        <label htmlFor="last-quarter">Last Quarter</label>
                      </div>
                      <div
                        onChange={(e) => getPeriod(e)}
                        className="form-group radio"
                      >
                        <input
                          name="period"
                          type="radio"
                          id="six-month"
                          value={"six months"}
                        />
                        <label htmlFor="six-month">This 6 Months</label>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="form">
                      <div className="form-group date">
                        <label htmlFor="from-date">From Date</label>
                        <input
                          id="from-date"
                          type="date"
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                      <div className="form-group date">
                        <label htmlFor="from-date">To Date</label>
                        <input
                          id="to-date"
                          type="date"
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Link
            to="#"
            className="btn-style"
            onClick={(e) => {
              getValue(e);
            }}
          >
            Apply
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { PeriodFilter };
