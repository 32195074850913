import {Link} from "react-router-dom";
import React, {useState} from 'react';


import {CoinHeader, InnerHeader} from "../components/Header";
import Footer from "../components/Footer";
import filterByIcon from "../assets/images/icons/filter-by-icon.svg";
import flagIcon from "../assets/images/icons/flag-icon.svg";
import Modal from "react-bootstrap/Modal";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

function MileStone() {

    const [showFilter, setShowFilter] = useState(false);

    const filterClose = () => setShowFilter(false);
    const filterOpen = () => setShowFilter(true);

    return (
        <>
            <CoinHeader pageTitle="Milestone 4" backIcon="left-arrow-icon.svg" buttonLabel="30093"/>

            <section className="mile-stone-wrapper">
                <div className="milestone-card-wrapper">
                    <h3>Get Assured Rs. 100 <br/>Cashback</h3>

                    <Link to="#" className="btn-style small light-blue icon rounded px-3">
                        <img src={`${flagIcon}`} alt="Notifications"/>
                        50000
                    </Link>

                    <h3>About</h3>

                    <ul>
                        <li><p>1.Place a Monthly Order Value
                            Above Rs. 30000 and get an
                            assured Reward of Rs. 100</p></li>
                        <li><p>2) Each order should be Rs. 500
                            & above</p></li>
                        <li><p>3) Win Cashback for every order
                            Rs. 500 & above</p></li>
                        <li><p>4) Win Cashback for every order
                            Rs. 500 & above</p></li>
                    </ul>
                </div>

                <Link to="#" className="btn-style">
                    ORDER NOW
                </Link>
            </section>

            <Footer/>

            <Modal show={showFilter} centered onHide={filterClose} className="filter-popup">
                <Modal.Header>
                    <a href="#" className="btn-style black medium transparent text icon">Filter by
                        <img src={`${filterByIcon}`} alt="Product"/>
                    </a>
                    <a href="#" className="close-popup" onClick={filterClose}>
                        Close
                        <img src={`${popupCloseIcon}`} alt="Icon"/>
                    </a>
                </Modal.Header>
                <Modal.Body>
                    <div className="filter-body-wrapper">
                        <Tab.Container defaultActiveKey="first">
                            <div className="filter-tabs-wrapper">
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Period</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Custom</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="form">
                                            <div className="form-group checkbox">
                                                <input type="checkbox" id="this-month"/>
                                                <label htmlFor="this-month">This Month</label>
                                            </div>
                                            <div className="form-group checkbox">
                                                <input type="checkbox" id="last-month"/>
                                                <label htmlFor="last-month">Last Month</label>
                                            </div>
                                            <div className="form-group checkbox">
                                                <input type="checkbox" id="last-quarter"/>
                                                <label htmlFor="last-quarter">Last Quarter</label>
                                            </div>
                                            <div className="form-group checkbox">
                                                <input type="checkbox" id="six-month"/>
                                                <label htmlFor="six-month">This 6 Month</label>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="form">
                                            <div className="form-group date">
                                                <label htmlFor="from-date">From Date</label>
                                                <input id="from-date" type="date"/>
                                            </div>
                                            <div className="form-group date">
                                                <label htmlFor="from-date">To Date</label>
                                                <input id="to-date" type="date"/>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a href="#" className="btn-style" onClick={filterClose}>Apply</a>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default MileStone;
