
import React from 'react';

import {InnerHeader} from "../components/Header";
import Footer from "../components/Footer";
import {WalletCardWrapper} from "../components/WalletCardWrapper";

function WalletView() {

    return (
        <>
            <InnerHeader pageTitle="Wallet" backIcon="left-arrow-icon.svg"/>

            <section className="wallet">
                <div className="container">
                    <WalletCardWrapper/>
                </div>
            </section>

            <Footer/>
        </>
    );
}

export default WalletView;
