import React, { useState, useEffect } from "react";
import { BASE_URL, WEB_URL, MOBILE_URL} from "../Constants";
import { InnerHeader } from "../components/Header";
import Footer from "../components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import popupCloseIcon from "../assets/images/icons/popup-cross-icon.svg";
import successIcon from "../assets/images/icons/success-icon.svg";
import radioCircleIcon from "../assets/images/icons/radio-circle-icon.svg";
import { getTokens } from "../helpers/helpers";
function CashRedeem() {
  const params = useParams();
  const [verifyOpen, setVerifyopen] = useState(false);
  const verifyopen = () => setVerifyopen(true);
  const verifyclose = () => setVerifyopen(false);
  const [bankaccount, setBankAccount] = useState();
  const [bank, setBank] = useState("bank");
  const [status, setStatus] = useState(false);
  const [verifyaccount, setVerifyBankAccount] = useState();
  const [ifs, setIFSC] = useState("");
  const [vpa, setVpa] = useState("");
  const [pan, setPan] = useState("pan");
  const [gstNumber, setGstNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [message, setMessage] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [panDisabled, setPanDisabled] = useState(false);
  const [gstDisabled, setGSTDisabled] = useState(false);

  const handleValidation = () => {
    let isValid = true;
    const bankAccountRegex = /^[0-9]{9,18}$/;
    const ifsRegex = /^[A-Za-z]{4}\d{7}$/;
    const upiRegex = /^[\w.-]+@[\w.-]+$/
    const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    const gstRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (bank === "bank") {
      if (pan === "pan") {
        if (panNumber !== "") {
          if (!panRegex.test(panNumber)) {
            isValid = false;
            setMessage("Invalid Pan Number");
          }
        }
      } else {
        if (gstNumber !== "") {
          if (!gstRegex.test(gstNumber)) {
            isValid = false;
            setMessage("Invalid GST Number");
          }
        }
      }
      if (!ifsRegex.test(ifs)) {
        isValid = false;
        setMessage("Invalid IFSC Code");
      }
      if (!bankAccountRegex.test(bankaccount)) {
        isValid = false;
        setMessage("Invalid Account Number");
      }
    } else {
      if (!upiRegex.test(vpa)) {
        isValid = false;
        setMessage("Invalid VPA Code");
      }
      if (pan === "pan") {
        if (panNumber !== "") {
          if (!panRegex.test(panNumber)) {
            isValid = false;
            setMessage("Invalid Pan Number");
          }
        }
      } else {
        if (gstNumber !== "") {
          if (!gstRegex.test(gstNumber)) {
            isValid = false;
            setMessage("Invalid GST Number");
          }
        }
      }
    }
    return isValid;
  };
  useEffect(() => {
    setBank("bank");
    fetchAccount();
  }, []);
  const submitVerify = async () => {
    try {
      let ep = `${BASE_URL}/web-sdk/api/redemptions/verify?theme=#c12424`;

      const response = await fetch(ep, {
        method: "POST",

        headers: {
          "X-Token": getTokens()?.token,
          "Content-Type": "application/json",
          "X-Access-Token": getTokens()?.pid,
          "Access-Control-Allow-Origin": "*",
        },
        body:
          bank === "bank"
            ? JSON.stringify({
              account_type: "bank",
              account_details: {
                account_number: bankaccount.toLowerCase(),
                ifsc: ifs.toLowerCase(),
                name: ownerName.toLowerCase(),
              },
            })
            : JSON.stringify({
              account_type: "upi",
              account_details: {
                vpa: vpa.toLowerCase(),
              },
            }),
      });
      const json = await response.json();

      verifyopen();
      console.log(json, "ok");
      console.log(json.account_status, "ok");
      if (json.is_valid == true) {
        setMessage("You can now transfer the amount");
        setStatus(true);
      } else {
        setMessage("Invalid Details");
        setStatus(false);
      }
    } catch (error) {
      console.log("error", error, "my error");
    }
  };
  async function call(e) {
    e.preventDefault();
    console.log(bankaccount, verifyaccount, ownerName, ifs, bank);

    if (bank == "bank" && bankaccount !== verifyaccount) {
      alert("Bank Account is not matched");
    } else {
      if (handleValidation()) {
        submitVerify();
      } else {
        verifyopen();
      }
    }
  }
  // useEffect(()=>{
  //   console.log(gstNumber)
  // },[gstNumber])
  async function transfer(e) {
    e.preventDefault();
    if (params.balance < 5) {
      verifyopen()
      setMessage("Minimum Amount that can we redeemed is ₹5")
    } else {
      if (bank == "bank" && bankaccount != verifyaccount) {
        alert("Bank Account is not matched");
      } else {
        try {
          let ep = `${BASE_URL}/web-sdk/api/redemptions`;
          const response = await fetch(ep, {
            method: "POST",

            headers: {
              "X-Token": getTokens()?.token,
              "Content-Type": "application/json",
              "X-Access-Token": getTokens()?.pid,
              "Access-Control-Allow-Origin": "*",
            },
            body:
              bank === "bank"
                ? JSON.stringify({
                  redemption: {
                    account_type: "bank",
                    account_number: bankaccount.toLowerCase(),
                    ac_name: ownerName.toLowerCase(),
                    ifsc: ifs.toLowerCase(),
                    pan: panNumber.toLowerCase(),
                    gst: gstNumber.toLowerCase(),
                  },
                })
                : JSON.stringify({
                  redemption: {
                    account_type: "upi",
                    pan: panNumber,
                    gst: gstNumber,
                    vpa: vpa.toLowerCase(),
                  },
                }),
          });
          const json = await response.json();

          console.log(json, "ok");
          if (json.success === true) {
            SuccessOpen();
          } else {
            alert(json.message);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  }

  async function fetchAccount() {

    try {
      let ep = `${BASE_URL}/web-sdk/api/redemptions/account`;
      const response = await fetch(ep, {
        method: "GET",

        headers: {
          "X-Token": getTokens()?.token,
          "Content-Type": "application/json",
          "X-Access-Token": getTokens()?.pid,
          "Access-Control-Allow-Origin": "*",
        }
      });
      const json = await response.json();

      if (json.success === true) {
        if (json.account.account_type == "bank") {
          setBank("bank");
        } else {
          setBank("upi");
        }
        setBankAccount(json.account.bank_account)
        setVerifyBankAccount(json.account.bank_account)
        setIFSC(json.account.ifsc)
        setOwnerName(json.account.ac_name)
        setVpa(json.account.vpa)

        setPanNumber(json.account.pan)
        setGstNumber(json.account.gst)
        if (json.account.pan) {
          setPanDisabled(true);
        }
        if (json.account.gst) {
          setGSTDisabled(true);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const [showUpload, setShowUpload] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const navigate = useNavigate();
  const uploadClose = () => setShowUpload(false);
  const uploadOpen = () => setShowUpload(true);
  const SuccessOpen = () => setShowSuccess(true);
  const SuccessClose = () => {
    setShowSuccess(false);
    navigate('/wallet');
  };
  return (
    <>
      <InnerHeader
        style={{ display: "inline-block", whiteSpace: "nowrap" }}
        pageTitle="Transactions Redeem"
        backIcon="left-arrow-icon.svg"
      />

      <section className="transactions-redeem-wrapper">
        <div className="container">
          <form className="form">
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <input
                    type="radio"
                    onChange={(e) => setBank(e.target.value)}
                    name="bank-name"
                    id="bank-name"
                    value="bank"
                    checked={bank === "bank"}
                    required
                  />
                  <label htmlFor="bank-name">
                    <span>
                      <img src={`${radioCircleIcon}`} alt="radio-Icon" />
                    </span>
                    <p>Bank Account Number</p>
                  </label>
                </div>
                <div className="col">
                  <input
                    type="radio"
                    onChange={(e) => setBank(e.target.value)}
                    value="upi"
                    name="bank-name"
                    id="upi-name"
                    checked={bank === "upi"}
                    required
                  />
                  <label htmlFor="upi-name">
                    <span>
                      <img src={`${radioCircleIcon}`} alt="radio-Icon" />
                    </span>
                    <p>UPI</p>
                  </label>
                </div>
              </div>
            </div>

            {bank === "bank" && (
              <div>
                <div className="form-group">
                  <input
                    type="text"
                    onChange={(e) => setOwnerName(e.target.value)}
                    className="form-control pen-icon"
                    placeholder="Enter Name"
                    value={ownerName}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Bank Account Number</label>
                  <input
                    onChange={(e) => setBankAccount(e.target.value)}
                    type="text"
                    className="form-control pen-icon"
                    placeholder="Enter Bank Account Number"
                    value={bankaccount}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Confirm Bank Account Number</label>
                  <input
                    onChange={(e) => setVerifyBankAccount(e.target.value)}
                    type="text"
                    className="form-control pen-icon"
                    placeholder="Enter Bank Account Number"
                    value={verifyaccount}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>IFSC Code</label>
                  <input
                    onChange={(e) => setIFSC(e.target.value)}
                    type="text"
                    className="form-control pen-icon"
                    placeholder="Enter IFSC Code"
                    value={ifs}
                    required
                  />
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-3">
                      <input
                        type="radio"
                        name="pan-gst"
                        id="pan"
                        value="pan"
                        defaultChecked
                        onChange={(e) => setPan(e.target.value)}
                        required
                      />
                      <label htmlFor="pan">
                        <span>
                          <img src={`${radioCircleIcon}`} alt="radio-Icon" />
                        </span>
                        <p>Pan</p>
                      </label>
                    </div>
                    <div className="col">
                      <input
                        type="radio"
                        name="pan-gst"
                        id="gst"
                        value="gst"
                        onChange={(e) => setPan(e.target.value)}
                        required
                      />
                      <label htmlFor="gst">
                        <span>
                          <img src={`${radioCircleIcon}`} alt="radio-Icon" />
                        </span>
                        <p>GST Number</p>
                      </label>
                    </div>
                  </div>
                  {pan === "pan" && (
                    <input
                      onChange={(e) => setPanNumber(e.target.value)}
                      type="text"
                      className="form-control pen-icon"
                      placeholder="Enter Pan Number"
                      disabled={(panDisabled) ? "disabled" : ""}
                      value={panNumber}
                      required
                    />
                  )}
                  {pan === "gst" && (
                    <input
                      onChange={(e) => setGstNumber(e.target.value)}
                      type="text"
                      className="form-control pen-icon"
                      placeholder="Enter GST Number"
                      disabled={(gstDisabled) ? "disabled" : ""}
                      value={gstNumber}
                      required
                    />
                  )}
                </div>
                <div className="form-group">
                  <div className="btn-wrapper text-center">
                    <input
                      type="submit"
                      value="VERIFY"
                      onClick={(e) => call(e)}
                      className='btn-style active'
                    />
                    {/*<Link to="#" onClick={call} className="btn-style disable active">
                  VERIFY
                </Link>*/}
                  </div>
                </div>
              </div>
            )}

            {bank === "upi" && (
              <div>
                <div className="form-group">
                  <label>VPA Code</label>
                  <input
                    onChange={(e) => setVpa(e.target.value)}
                    type="text"
                    className="form-control pen-icon"
                    placeholder="Enter UPI ID"
                    value={vpa}
                    required
                  />
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-3">
                      <input
                        type="radio"
                        defaultChecked
                        name="pan-gst"
                        id="pan"
                        value="pan"
                        onChange={(e) => setPan(e.target.value)}
                        required
                      />
                      <label htmlFor="pan">
                        <span>
                          <img src={`${radioCircleIcon}`} alt="radio-Icon" />
                        </span>
                        <p>Pan</p>
                      </label>
                    </div>
                    <div className="col">
                      <input
                        type="radio"
                        name="pan-gst"
                        id="gst"
                        value="gst"
                        onChange={(e) => setPan(e.target.value)}
                        required
                      />
                      <label htmlFor="gst">
                        <span>
                          <img src={`${radioCircleIcon}`} alt="radio-Icon" />
                        </span>
                        <p>GST Number</p>
                      </label>
                    </div>
                  </div>
                  {pan === "pan" && (
                    <input
                      onChange={(e) => setPanNumber(e.target.value)}
                      type="text"
                      className="form-control pen-icon"
                      placeholder="Enter Pan Number"
                      disabled={panDisabled ? "disabled" : ""}
                      value={panNumber}
                      required
                    />
                  )}
                  {pan === "gst" && (
                    <input
                      onChange={(e) => setGstNumber(e.target.value)}
                      type="text"
                      className="form-control pen-icon"
                      placeholder="Enter GST Number"
                      disabled={gstDisabled ? "disabled" : ""}
                      value={gstNumber}
                      required
                    />
                  )}
                </div>
                <div className="form-group">
                  <div className="btn-wrapper text-center">
                    <input
                      type="submit"
                      value="VERIFY"
                      onClick={(e) => call(e)}
                      className='btn-style active'
                    />
                  </div>
                </div>
              </div>
            )}

            {/* {(bank !== "upi" && bank !== "bank") &&
                <div>
                  <h3 className="select-bank-title">Please Select Bank Account</h3>
                </div>

            }
*/}
          </form>

          {(bank === "upi" || bank === "bank") && (
            <div>
              <p>

                ₹{(Math.round(params.balance * 100) / 100).toFixed(2)} will be transferred into the above mentioned bank
                account
              </p>
              <div className="btn-wrapper  text-center">
                {status ? (
                  <div to="#" onClick={transfer} className="btn-style ">
                    TRANSFER
                  </div>
                ) : (
                  <div disabled={true} className="btn-style disable">
                    TRANSFER
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </section>

      <Footer />

      <Modal
        show={showUpload}
        centered
        onHide={uploadClose}
        className="transaction-popup"
      >
        <Modal.Header>
          <a href="#" className="close-popup" onClick={SuccessClose}>
            Close
            <img src={`${popupCloseIcon}`} alt="Icon" />
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="transactions-details-wrapper ">
            <h2>Details</h2>
            <p className="blue">Cashback Amount : ₹ 4876</p>
            <p>Company : Abbott</p>
            <p>Brand : Xapilis</p>
            <p>Scheme Month : Feb ‘ 23</p>
            <p>Invoice : ABC123</p>
            <p>Order ID : ABC123</p>
            <p>Bank Ref ID : ABC123</p>
            <div className="btn-wrapper text-center">
              <Link to="#" className="btn-style">
                OK
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSuccess}
        centered
        onHide={SuccessClose}
        className="success-popup"
      >
        <Modal.Header>
          <a href="#" className="close-popup" onClick={SuccessClose}>
            Close
            <img src={`${popupCloseIcon}`} alt="Icon" />
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="success-body-wrapper text-center">
            <img src={`${successIcon}`} alt="Success-upload" />
            <h3>Congratulations.!!</h3>
            <p>
              Congratulations, Amount has been credited <br />
              to your account.
            </p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={verifyOpen}
        centered
        onHide={verifyclose}
        className="success-popup"
      >
        <Modal.Header>
          <a href="#" className="close-popup" onClick={verifyclose}>
            Close
            <img src={`${popupCloseIcon}`} alt="Icon" />
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="success-body-wrapper text-center">
            <h3>{message}</h3>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CashRedeem;
