import React, { useEffect, useState } from "react";
import {BASE_URL} from "../Constants"
import { WalletHeader } from "../components/Header";
import Footer from "../components/Footer";
import { CashFilter } from "../components/AmountFilter";
import { PeriodFilter } from "../components/PeriodFilter";
import {
  DecMonthCardWrapper,
  JanMonthCardWrapper,
  OctMonthCardWrapper,
} from "../components/MonthCardWrapper";
import {getTokens } from "../helpers/helpers";
function WalletCash() {
  const [data, setDate] = useState();
  const [walletType, setWalletType] = useState();

  useEffect(() => {
    getProductDeal();
  }, []);
  const getProductDeal = async () => {
    try {
      let ep=  `${BASE_URL}/web-sdk/api/users/wallet/upi_bank_account?page=1&per_page=10&theme=#c12424`
      
      const searchParams = new URLSearchParams(ep.substring(ep.indexOf('?')));
      const response = await fetch(
       ep,
        {
          method: "GET",
          headers: { 
             "X-Token": getTokens()?.token,
            "X-Access-Token": getTokens()?.pid,
          },
        }
      );
      const json = await response.json();
      // setFilterBy(json);
        setWalletType(json.wallet_data.currency_code)
      console.log(json.wallet_data.currency_code, "ok awa ");
      console.log(json.wallet_data.balance, "ok ok ");
      setDate(json.wallet_data.balance);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <WalletHeader
        pageTitle="Wallet"
        pageTitle1={"₹" + data}
        pageTitle2="Rewards Won"
        backIcon="left-arrow-icon.svg"
        className="Wallet-Header"
        walletLink={walletType === "upi_bank_account" ? "cash-redeem/1" : "/gift-cards"}
      />

     {/* <section className="wallet-cash-wrapper">
        <div className="container">
          <div className="product-filter">
            <PeriodFilter />
            <CashFilter />
          </div>
        </div>


         <DecMonthCardWrapper/>
                <OctMonthCardWrapper/>
      </section>
*/}
      
      <JanMonthCardWrapper />

      <Footer />
    </>
  );
}

export default WalletCash;
